import {API_URL} from "../constants/constants"

export const getCatalogDescendants = (id: any) => {
    return new Promise((resolve, reject) => {
        return fetch(API_URL + "catalog/descendants/" + id, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsYWxhIjoibGVsZSIsImlhdCI6MTYwNDE5Nzg2M30.q5KFfzMqBDfiO5QTCTGbYmKfo6sOxrp8rxZrX1UIWbU"
            },
        }).then(response => response.json()).then(responseJson => {
            resolve(responseJson)
        }).catch(error => {
            reject(error)
        });
    });
};
