import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {RouteComponentProps} from "react-router";
import Row from "react-bootstrap/cjs/Row";
import Col from "react-bootstrap/cjs/Col";
import ItemTool from "../components/ItemTool";
import {Needs, Tools} from "../constants/constants";
import {urlFor} from "../utils/UrlGenerator";

interface OnBoardingProps extends RouteComponentProps {
}

class OnBoarding extends Component<OnBoardingProps, any> {
    handleClickTool = (tool: any) => {
        const {history} = this.props;
        history.push(urlFor(tool.routeName));
    };

    render() {
        return <Row className={"body-home"}>
            <Col>
                <Row className="mt-5 p-2">
                    <Col className={"mt-5 p-2"}>
                        <Row className="d-flex justify-content-center">
                            <h3 className={"text-center"}>Herramientas Financieras</h3>
                        </Row>
                        <Row className="d-flex justify-content-center mt-2">
                            <p className={"text-center"}>Calcula el costo de tus sueños</p>
                        </Row>
                        <Row className="d-flex justify-content-center mt-5 funfact_ccn_feature">
                            {Tools.map(tool => <ItemTool key={tool.routeName} item={tool}
                                                         handle={this.handleClickTool}/>)}
                        </Row>
                    </Col>
                </Row>
                <Row className="mt-5 p-2">
                    <Col className="mt-5 p-2">
                        <Row className="d-flex justify-content-center">
                            <h3 className={"text-center"}>Necesidades Financieras</h3>
                        </Row>
                        <Row className="d-flex justify-content-center mt-2">
                            <p className={"text-center"}>Planifica tus acciones para un futuro mejor</p>
                        </Row>
                        <Row className="d-flex justify-content-center mt-5 funfact_ccn_feature">
                            {Needs.map(tool => <ItemTool key={tool.routeName} item={tool}
                                                         handle={this.handleClickTool}/>)}
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>;
    }
}

export default withRouter(OnBoarding);