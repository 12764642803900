import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import {FACEBOOK_URL, INSTAGRAM_URL, TWITTER_URL, YOUTUBE_URL, LINKEDIN_URL} from "../constants/constants";

const Footer = () => {
    return (
        <Navbar className={"sb-footer-bar fixed-bottom"}>
            <Col>
                <Row>
                    <Navbar.Text className={"sb-footer col"}>
                        <Container>
                            <Col sm={4} md={4} lg={4}>
                                <img className={"logo-navbar logo-navbar-footer"}
                                     alt="logo"/>
                            </Col>
                            <Col sm={8} md={4} lg={5}/>
                            <Col sm={12} md={2} lg={3} xl={3}>
                                <Row>
                                    <a target={"_blank"} rel={"noopener noreferrer"} href={FACEBOOK_URL}
                                       className={"col"}>
                                        <i className={"fa fa-facebook text-white"}/>
                                    </a>
                                    <a target={"_blank"} rel={"noopener noreferrer"} href={TWITTER_URL}
                                       className={"col"}>
                                        <i className={"fa fa-twitter text-white"}/>
                                    </a>
                                    <a target={"_blank"} rel={"noopener noreferrer"} href={INSTAGRAM_URL}
                                       className={"col"}>
                                        <i className={"fa fa-instagram text-white"}/>
                                    </a>
                                    <a target={"_blank"} rel={"noopener noreferrer"} href={YOUTUBE_URL}
                                       className={"col"}>
                                        <i className={"fa fa-youtube-play text-white"}/>
                                    </a>
                                    <a target={"_blank"} rel={"noopener noreferrer"} href={LINKEDIN_URL}
                                       className={"col"}>
                                        <i className={"fa fa-linkedin text-white"}/>
                                    </a>
                                </Row>
                            </Col>
                        </Container>
                    </Navbar.Text>
                </Row>
            </Col>
        </Navbar>
    );
};

export default Footer;
