import React from "react";
import Alert from "react-bootstrap/Alert";
import Col from "react-bootstrap/Col";

const Title = (props: any) => {
    return (
        <div className={"row"}>
            <div className={"col"}>
                <div className={"row mt-3 mb-2"}>
                    <Col sm={2}>
                        <div className={"back-container"} onClick={props.handleClick}>
                            <img className={"icon-back"} alt=""/>
                        </div>
                    </Col>
                    <Col sm={8}>
                        <h2 className={"d-flex justify-content-center tool-title"}>{(props ? props.txtTitle : "Herramientas Financieras").toUpperCase()}</h2>
                    </Col>
                    <Col sm={2}/>
                </div>
                <div className={"row"}>
                    <div className={"col"}>
                        <Alert variant="primary sb-tool-oe-instructions">
                            <div className="d-flex">
                                <section className="d-flex flex-column justify-content-center">
                                    <img className="sb-icon-instructions" alt=""/>
                                </section>
                                <section>
                                    <Alert.Heading>Instrucciones de uso</Alert.Heading>
                                    <p>
                                        Para registrar la información, llena los siguientes campos de texto cumpliendo
                                        las
                                        siguientes condiciones:
                                    </p>
                                    <hr/>
                                    <p className="mb-0">
                                        - Para decimales, utilizar puntos. Ej. $250.50. <br/>
                                        - No se permiten letras o símbolos especiales.
                                    </p>
                                </section>
                            </div>

                        </Alert>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Title;
