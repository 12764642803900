import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

const SuccessModal = (props: any) => {
    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Alert className={"margin-bottom-0"}>
                <Alert.Heading>{props.txtdescription}</Alert.Heading>
                <hr/>
                <div className="d-flex justify-content-end">
                    <Button onClick={props.onHide} variant="outline-success">
                        OK
                    </Button>
                </div>
            </Alert>
        </Modal>
    );
};

export default SuccessModal;
