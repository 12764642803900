import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const AlertModel = (props: any) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{props.txttitle}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>{props.txtdescription}</p>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>OK</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AlertModel;
