import React, {Component} from "react";
import Card from "react-bootstrap/cjs/Card";
import Row from "react-bootstrap/cjs/Row";
import Col from "react-bootstrap/cjs/Col";
import Form from "react-bootstrap/cjs/Form";
import Alert from "react-bootstrap/cjs/Alert";
import Image from "react-bootstrap/Image";
import DescriptionModal from "../components/DescriptionModal";
import Chart from "../components/Chart";
import NumberInput from "../components/NumberInput";
import {CREDIT_CARD_RESULTS} from "../constants/constants";
import {getCatalogDescendants} from "../actions/BudgetTool";
import {Redirect} from "react-router-dom";
import LoadingModal from "../components/LoadingModal";
import ErrorModal from "../components/ErrorModal";
import Title from "../components/Title";
import {sendMail} from "../actions/Global";
import ExportButton from "../components/ExportButton";
import EmailModal from "../components/EmailModal";
import SuccessModal from "../components/SuccessModal";
import AlertModal from "../components/AlertModal";

const fullSquirrel = require("../assets/images/design/full-squirrel.png");

class CreditCard extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            toolTitle: "",
            description: "",
            inputs: {
                debt: {title: "Cantidad de deuda en la tarjeta de crédito", value: ""},
                interestMonth: {title: "Interés que pagas en la tarjeta de crédito (mensual)", value: ""},
                monthlyPayment: {title: "Cantidad del pago mensual", value: ""},
                month: {title: "Número de meses para el pago de tarjeta de crédito", value: ""},
            },
            monthsToPay: {title: "Cálculo por número de meses", value: false},
            total: {monthsToPay: 0, amountToPay: 0, result: ""},
            charts: {
                debtLevelPie: {
                    type: "pie",
                    options: {
                        title: {
                            text: ""
                        },
                        tooltip: {
                            pointFormat: "{series.name}: <b>($ {point.y}) {point.percentage:.1f}%</b>"
                        },
                        legend: {
                            layout: "vertical",
                            align: "right",
                            verticalAlign: "middle",
                            itemMarginTop: 10,
                            itemMarginBottom: 10
                        },
                        series: []
                    }
                },
            },
            condModal: false,
            loading: true,
            condError: false,
            redirect: null,
            emailAddress: "",
            condEmailModal: false,
            attachmentId: null,
            textError: "Servicio no disponible por el momento, intente más tarde...",
            condModalEmailSuccess: false,
            txtAlertModal: "",
            condRedirect: false,
            condAlertModal: false
        };
    }

    chatsIng: object[] = [];

    async componentDidMount() {
        let condError = false;
        try {
            let budgetData: any = await getCatalogDescendants(98);
            if (!budgetData.message)
                this.setState({
                    description: budgetData.jsonData.description,
                    toolTitle: budgetData.name,
                    condModal: true,
                    attachmentId: budgetData.jsonData.attachment
                });
            else
                condError = true;
        } catch (error) {
            condError = true;
        }
        this.setState({condError, loading: false, condRedirect: condError});
    }

    async _sendMail() {
        let tables: any = [];
        const {inputs, monthsToPay, total} = this.state;
        tables.push({
            label: inputs.debt.title,
            value: isFinite(inputs.debt.value) ? parseFloat(inputs.debt.value).toFixed(2) : "0.00"
        });
        tables.push({
            label: inputs.interestMonth.title,
            value: isFinite(inputs.interestMonth.value) ? parseFloat(inputs.interestMonth.value).toFixed(2) : "0.00"
        });
        tables.push({
            label: monthsToPay.value ? inputs.month.title : inputs.monthlyPayment.title,
            value: monthsToPay.value ? (isFinite(inputs.month.value) ? parseInt(inputs.month.value).toFixed(0) : "0") :
                (isFinite(inputs.monthlyPayment.value) ? parseFloat(inputs.monthlyPayment.value).toFixed(2) : "0.00")
        });
        let dataForm = {
            "title": this.state.toolTitle,
            "messages": [
                {
                    "text": total.result,
                    "type": "primary"
                }
            ],
            "table": tables,
            charts: this.chatsIng
        };
        let body = {
            "to": this.state.emailAddress,
            "params": {
                "name": this.state.toolTitle,
                "description": this.state.description
            },
            "attachmentsParams": [
                {
                    "id": this.state.attachmentId,
                    "params": dataForm
                }
            ]
        };
        try {
            await this.setState({condEmailModal: false, loading: true});
            await sendMail(body);
            this.setState({loading: false, condModalEmailSuccess: true});
        } catch (e) {
            this.setState({
                textError: "Ocurrió un error al enviar la información, intente nuevamente.",
                condError: true,
                loading: false,
                condRedirect: false
            });
        }
    }

    render() {
        const {inputs: {debt, interestMonth, monthlyPayment, month}, monthsToPay, total, charts} = this.state;
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>;
        }
        return <Row>
            {this.state.toolTitle !== "" ? (
                <Col>
                    <Title txtTitle={this.state.toolTitle} handleClick={() => {
                        this.setState({redirect: "/"});
                    }}/>
                    <Card className="mt-3 sb-card-content">
                        <Card.Body>
                            <Row>
                                <Col xl={7} lg={12} md={12} sm={12}>
                                    <Form>
                                        <NumberInput titleClass={"col-md-6 col-sm-12 col-xs-12"}
                                                     inputClass={"col-md-6 col-sm-12 col-xs-12"}
                                                     name="debt"
                                                     prepend={"$"} title={debt.title}
                                                     handleChangeInput={this.handleChangeInput}
                                                     placeholder={"0.00"}/>
                                        <NumberInput titleClass={"col-md-6 col-sm-12 col-xs-12"}
                                                     inputClass={"col-md-6 col-sm-12 col-xs-12"} name="interestMonth"
                                                     prepend="%" title={interestMonth.title}
                                                     handleChangeInput={this.handleChangeInput}
                                                     placeholder={"0.00"}/>
                                        <Row className={"mb-3"}>
                                            <Col md={6} sm={12} xs={12}>
                                                <Form.Check className={"check-credit-card"}
                                                            inline
                                                            label="Calcular por valor a pagar mensual"
                                                            type={"radio"}
                                                            id={`custom-inline-radio-2`}
                                                            checked={!monthsToPay.value}
                                                            onChange={() => {
                                                                this.setState({
                                                                    ...this.state,
                                                                    monthsToPay: {
                                                                        ...this.state.monthsToPay,
                                                                        value: false
                                                                    }
                                                                });
                                                            }}
                                                />
                                            </Col>
                                            <Col md={6} sm={12} xs={12}>
                                                <Form.Check className={"check-credit-card"}
                                                            inline
                                                            label="Calcular por cantidad de meses"
                                                            type={"radio"}
                                                            id={`custom-inline-radio-2`}
                                                            checked={monthsToPay.value}
                                                            onChange={() => {
                                                                this.setState({
                                                                    ...this.state,
                                                                    monthsToPay: {
                                                                        ...this.state.monthsToPay,
                                                                        value: true
                                                                    }
                                                                });
                                                            }}
                                                />
                                            </Col>
                                        </Row>
                                        {monthsToPay.value ? <NumberInput
                                                prepend="#" name="month"
                                                title={month.title}
                                                handleChangeInput={this.handleChangeInput}
                                                titleClass={"col-md-6 col-sm-12 col-xs-12"}
                                                inputClass={"col-md-6 col-sm-12 col-xs-12"}
                                            /> :
                                            <NumberInput
                                                prepend="$"
                                                name="monthlyPayment" title={monthlyPayment.title}
                                                handleChangeInput={this.handleChangeInput}
                                                titleClass={"col-md-6 col-sm-12 col-xs-12"}
                                                inputClass={"col-md-6 col-sm-12 col-xs-12"}
                                                placeholder={"0.00"}
                                            />
                                        }
                                    </Form>
                                </Col>
                                <Col xl={5} lg={12} md={12} sm={12}>
                                    {total.result && isFinite(total.monthsToPay) && isFinite(total.amountToPay) ?
                                        <div>
                                            <Alert variant="primary" className="col-12">
                                                <p>{total.result}</p>
                                            </Alert>
                                            <Chart type={charts.debtLevelPie.type}
                                                   callback={this.getReports}
                                                   options={charts.debtLevelPie.options}/>
                                        </div>
                                        : <div className={"container-squirrel-explain"}>
                                            <div className={"container-speech"}>
                                                <h3>Tus resultados se mostrarán aquí</h3>
                                            </div>
                                            <Image src={fullSquirrel} fluid={true}/>
                                        </div>}
                                </Col>
                            </Row>
                            <Row className={"mt-4"}>
                                <Col>
                                    <div className={"text-center"}>
                                        <ExportButton onClick={() => {
                                            if (total.result && isFinite(total.monthsToPay) && isFinite(total.amountToPay))
                                                this.setState({condEmailModal: true});
                                            else
                                                this.setState({
                                                    condAlertModal: true,
                                                    txtAlertModal: "Ingrese su información para realizar la exportación."
                                                });
                                        }}/>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>) : <div/>}
            <DescriptionModal
                show={this.state.condModal}
                onHide={() => {
                    this.setState({condModal: !this.state.condModal});
                }}
                txtdescription={this.state.description}
            />
            <LoadingModal
                show={this.state.loading}
                onHide={() => {
                    this.setState({loading: !this.state.loading});
                }}
            />
            <ErrorModal
                show={this.state.condError}
                onHide={() => {
                    this.setState({
                        condError: !this.state.condError,
                        redirect: this.state.condRedirect ? "/" : null,
                    });
                }}
                txtdescription={this.state.textError}
            />
            <EmailModal
                show={this.state.condEmailModal}
                value={this.state.emailAddress}
                onHide={() => {
                    this.setState({condEmailModal: false});
                }}
                onChange={(e: any) => {
                    this.setState({emailAddress: e.target.value});
                }}
                handleSubmit={(event: any) => {
                    event.preventDefault();
                    this._sendMail();
                }}
                txtdescription={this.state.description}
            />
            <SuccessModal
                show={this.state.condModalEmailSuccess}
                onHide={() => {
                    this.setState({condModalEmailSuccess: !this.state.condModalEmailSuccess});
                }}
                txtdescription={"¡Información enviada correctamente!"}
            />
            <AlertModal
                show={this.state.condAlertModal}
                onHide={() => {
                    this.setState({condAlertModal: !this.state.condAlertModal});
                }}
                txtdescription={this.state.txtAlertModal}
                txttitle={"Información"}
            />
        </Row>;
    }

    handleChangeInput = (element: any) => {
        const {target: {name, value}} = element;
        const {inputs} = this.state;
        inputs[name].value = isFinite(value) ? parseFloat(value) : 0;
        this.setState({inputs, ...this.processResult(inputs)}, () => {
            console.log(this.state.total);
        });
    };

    handleChangeType = (element: any) => {
        const {target: {checked}} = element;
        const {monthsToPay, inputs} = this.state;
        if (checked) {
            inputs.monthlyPayment.value = 0;
        } else {
            inputs.month.value = 0;
        }
        monthsToPay.value = checked;
        this.setState({monthsToPay, inputs, ...this.processResult(inputs, monthsToPay)});
    };


    processResult(inputs: any, monthsToPay = this.state.monthsToPay) {
        let condProcess = true;
        const {total, charts} = this.state;
        for (const inputData in inputs) {
            if (inputs.hasOwnProperty(inputData)) {
                if (parseFloat(inputs[inputData].value) <= 0) {
                    if (monthsToPay.value && inputData !== "monthlyPayment") {
                        condProcess = false;
                    } else if (!monthsToPay.value && inputData !== "month") {
                        condProcess = false;
                    }
                }
            }
        }
        if (condProcess) {
            if (monthsToPay.value) {
                total.monthsToPay = inputs.month.value;
                total.amountToPay = ((inputs.debt.value * (1 + inputs.interestMonth.value / 100)) / total.monthsToPay)
                    .toFixed(2);
                total.result = CREDIT_CARD_RESULTS["month"];
            } else {
                total.amountToPay = inputs.monthlyPayment.value;
                total.monthsToPay =
                    ((inputs.debt.value * (1 + inputs.interestMonth.value / 100)) / total.amountToPay).toFixed(0);
                total.result = CREDIT_CARD_RESULTS["monthlyPayment"];
            }
            total.result = total.result.replace("{monthlyPayment}", isFinite(total.amountToPay) ? total.amountToPay : 0)
                .replace("{months}", isFinite(total.monthsToPay) ? total.monthsToPay : 0);
            charts.debtLevelPie.options.series = [{
                name: "Valor",
                colorByPoint: true,
                data: [
                    {
                        name: "Capital Pagado",
                        y: parseFloat(inputs.debt.value) || 0,
                    },
                    {
                        name: "Interés Pagado",
                        y: parseFloat((inputs.debt.value * (inputs.interestMonth.value / 100)).toFixed(2)) || 0,
                        color: "red"
                    }
                ]
            }];
        }
        return {total, charts};
    }

    getReports = (chart: any): void => {
        this.chatsIng[0] = {graph: chart};
    };
}

export default CreditCard;
