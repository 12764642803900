import React, {Component} from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Title from "../components/Title";
import InputGroup from "react-bootstrap/InputGroup";
import DescriptionModal from "../components/DescriptionModal";
import {getCatalogDescendants} from "../actions/BudgetTool";
import {Redirect} from "react-router-dom";
import LoadingModal from "../components/LoadingModal";
import ErrorModal from "../components/ErrorModal";
import Image from "react-bootstrap/Image";
import {sendMail} from "../actions/Global";
import EmailModal from "../components/EmailModal";
import SuccessModal from "../components/SuccessModal";
import AlertModal from "../components/AlertModal";
import ExportButton from "../components/ExportButton";
import Card from "react-bootstrap/cjs/Card";

const fullSquirrel = require("../assets/images/design/full-squirrel.png");

class FinancialGoalTool extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            toolTitle: "",
            goalsData: [
                {
                    description: "",
                    neededAmount: "",
                    goalDuration: "",
                    amountToSave: ""
                }
            ],
            description: "",
            condSaving: false,
            maxDuration: 0,
            savingAmount: 0,
            condModal: false,
            loading: true,
            condError: false,
            redirect: null,
            placeholderExamples: [
                "Ej. Comprar automóvil.",
                "Ej. Comprar una casa.",
                "Ej. Comprar computadora.",
                "Ej. Viajar al exterior."
            ],
            emailAddress: "",
            condEmailModal: false,
            attachmentId: null,
            textError: "Servicio no disponible por el momento, intente más tarde...",
            condModalEmailSuccess: false,
            txtAlertModal: "",
            condRedirect: false,
            condAlertModal: false
        };
    }

    async componentDidMount() {
        let condError = false;
        try {
            let toolData: any = await getCatalogDescendants(96);
            if (!toolData.message)
                this.setState({
                    description: toolData.jsonData.description,
                    toolTitle: toolData.name,
                    condModal: true,
                    attachmentId: toolData.jsonData.attachment
                });
            else
                condError = true;
        } catch (error) {
            condError = true;
        }
        this.setState({condError, loading: false, condRedirect: condError});
    }

    async _sendMail() {
        let tables: any = [];
        const {goalsData, savingAmount, maxDuration} = this.state;

        goalsData.forEach((input: any) => {
            tables.push({
                goal: input.description,
                term: isFinite(input.goalDuration) ? parseInt(input.goalDuration) : "0",
                saving: isFinite(input.amountToSave) ? parseFloat(input.amountToSave).toFixed(2) : "0.00",
                quantity: isFinite(input.neededAmount) ? parseFloat(input.neededAmount).toFixed(2) : "0.00"
            });
        });

        let result = `<p style="text-align: justify;">Para alcanzar tus metas financieras, 
deberás ahorrar <strong>$${savingAmount.toFixed(2)}</strong> por <strong>${maxDuration} meses</strong></p>`;

        let dataForm = {
            "title": this.state.toolTitle,
            "messages": [
                {
                    "text": result,
                    "type": "success"
                }
            ],
            "tables": tables
        };
        let body = {
            "to": this.state.emailAddress,
            "params": {
                "name": this.state.toolTitle,
                "description": this.state.description
            },
            "attachmentsParams": [
                {
                    "id": this.state.attachmentId,
                    "params": dataForm
                }
            ]
        };
        try {
            await this.setState({condEmailModal: false, loading: true});
            await sendMail(body);
            this.setState({loading: false, condModalEmailSuccess: true});
        } catch (e) {
            this.setState({
                textError: "Ocurrió un error al enviar la información, intente nuevamente.",
                condError: true,
                loading: false,
                condRedirect: false
            });
        }
    }

    _addRow = () => {
        let goalsData = this.state.goalsData;
        goalsData.push({
            description: "",
            neededAmount: "",
            goalDuration: "",
            amountToSave: ""
        });
        this.setState({goalsData});
    };

    _calculateSaving = () => {
        let maxDuration = 0;
        let savingAmount = 0;
        this.state.goalsData.forEach((goalData: any) => {
            if (goalData.goalDuration !== "" && isFinite(goalData.goalDuration) && (parseInt(goalData.goalDuration) > maxDuration)) {
                maxDuration = parseInt(goalData.goalDuration);
            }
            savingAmount += (goalData.goalDuration !== "" && isFinite(goalData.amountToSave)) ? parseFloat(goalData.amountToSave) : 0;
        });
        if (savingAmount > 0) {
            this.setState({maxDuration, savingAmount, condSaving: true});
        } else {

        }
    };

    _handleInputChange = (index: number, e: any, name: any) => {
        let goalsDataAux = this.state.goalsData;
        goalsDataAux.forEach((goalData: any, goalIndex: any) => {
            if (index === goalIndex) {
                goalsDataAux[index][name] = e.target.value;
                //calculate row saving
                if (isFinite(goalsDataAux[index].neededAmount) && isFinite(goalsDataAux[index].goalDuration) && (parseInt(goalsDataAux[index].goalDuration) > 0)) {
                    goalsDataAux[index].amountToSave = parseFloat(goalsDataAux[index].neededAmount) / parseFloat(goalsDataAux[index].goalDuration);
                } else {
                    goalsDataAux[index].amountToSave = 0;
                }
            }
        });
        this.setState({goalsData: goalsDataAux});
    };

    _deleteRow = (index: number) => {
        let goalsDataAux = this.state.goalsData;
        goalsDataAux.splice(index, 1);
        this.setState({goalsData: goalsDataAux});
    };

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>;
        }
        return (
            <div className={"row"}>
                {this.state.toolTitle !== "" ? (
                    <div className={"col"}>
                        <Title txtTitle={this.state.toolTitle} handleClick={() => {
                            this.setState({redirect: "/"});
                        }}/>
                        <Card className={"mt-3 sb-card-content"}>
                            <Card.Body>
                                <div className={"row"}>
                                    <div className={"col"}>
                                        <Form>
                                            {this.state.goalsData.map((goal: any, index: number) => {
                                                let valueTemp = Math.floor(index / this.state.placeholderExamples.length);
                                                return (
                                                    <Form.Row key={index} className={"container-form-row"}>
                                                        <Col md="12" className={"font-weight-bold"}>
                                                            Objetivo {index + 1}
                                                            <Button variant="danger" className={"ml-2 btn-delete-row"}
                                                                    size="sm"
                                                                    type="button"
                                                                    onClick={() => {
                                                                        this._deleteRow(index);
                                                                    }}>-</Button>
                                                        </Col>
                                                        <Form.Group as={Col} controlId="metaDescripcion" sm="auto"
                                                                    md="4">
                                                            <Form.Label>Definir Objetivo/Meta</Form.Label>
                                                            <Form.Control type="text"
                                                                          placeholder={this.state.placeholderExamples[index - (valueTemp * this.state.placeholderExamples.length)]}
                                                                          value={goal.description} onChange={(e) => {
                                                                this._handleInputChange(index, e, "description");
                                                            }}/>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="metaCantidad" sm="auto" md="3">
                                                            <Form.Label>Cantidad</Form.Label>
                                                            <InputGroup>
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text>$</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <Form.Control type="number" placeholder="0.00"
                                                                              value={goal.neededAmount}
                                                                              onChange={(e) => {
                                                                                  this._handleInputChange(index, e, "neededAmount");
                                                                              }}/>
                                                            </InputGroup>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="metaTiempo" sm="auto" md="2">
                                                            <Form.Label>Tiempo meta (meses)</Form.Label>
                                                            <Form.Control type="number" placeholder="Ej. 36"
                                                                          value={goal.goalDuration}
                                                                          onChange={(e) => {
                                                                              this._handleInputChange(index, e, "goalDuration");
                                                                          }}/>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="metaCantidadAhorro" sm="auto"
                                                                    md="3">
                                                            <Form.Label>Cantidad a ahorrar mensual</Form.Label>
                                                            <InputGroup>
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text>$</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <Form.Control readOnly type="number" placeholder="0.00"
                                                                              value={(goal.amountToSave !== "" && isFinite(goal.amountToSave)) ? parseFloat(goal.amountToSave).toFixed(2) : "0"}/>
                                                            </InputGroup>
                                                        </Form.Group>
                                                    </Form.Row>
                                                );
                                            })}

                                            <Button variant="primary" className={"mt-2 mr-2"} type="button"
                                                    onClick={this._addRow}><i className={"la la-plus"}/> Agregar
                                                Objetivo</Button>
                                            <Button variant="success" className={"mt-2"} type="button"
                                                    onClick={this._calculateSaving}>Calcular Ahorro</Button>

                                            {this.state.condSaving ? (<Row className={"mt-4"}>
                                                <Col>
                                                    <div className={"container-squirrel-explain"}>
                                                        <div className={"container-speech-result"}>
                                                            <p>
                                                                Para alcanzar tus metas financieras, deberás
                                                                ahorrar <span
                                                                className={"font-weight-bold"}>${this.state.savingAmount.toFixed(2)}</span> por <span
                                                                className={"font-weight-bold"}>{this.state.maxDuration} meses</span>.
                                                            </p>
                                                        </div>
                                                        <Image src={fullSquirrel} fluid={true}/>
                                                    </div>
                                                </Col>
                                            </Row>) : (<Row/>)}
                                        </Form>
                                    </div>
                                </div>
                                <Row className={"mt-4"}>
                                    <Col>
                                        <div className={"text-center"}>
                                            <ExportButton onClick={() => {
                                                if (this.state.condSaving)
                                                    this.setState({condEmailModal: true});
                                                else
                                                    this.setState({
                                                        condAlertModal: true,
                                                        txtAlertModal: "Calcule primero su ahorro para realizar la exportación."
                                                    });
                                            }}/>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body></Card>
                    </div>) : <div/>}
                <DescriptionModal
                    show={this.state.condModal}
                    onHide={() => {
                        this.setState({condModal: !this.state.condModal});
                    }}
                    txtdescription={this.state.description}
                />
                <LoadingModal
                    show={this.state.loading}
                    onHide={() => {
                        this.setState({loading: !this.state.loading});
                    }}
                />
                <ErrorModal
                    show={this.state.condError}
                    onHide={() => {
                        this.setState({
                            condError: !this.state.condError,
                            redirect: this.state.condRedirect ? "/" : null,
                        });
                    }}
                    txtdescription={this.state.textError}
                />
                <EmailModal
                    show={this.state.condEmailModal}
                    value={this.state.emailAddress}
                    onHide={() => {
                        this.setState({condEmailModal: false});
                    }}
                    onChange={(e: any) => {
                        this.setState({emailAddress: e.target.value});
                    }}
                    handleSubmit={(event: any) => {
                        event.preventDefault();
                        this._sendMail();
                    }}
                    txtdescription={this.state.description}
                />
                <SuccessModal
                    show={this.state.condModalEmailSuccess}
                    onHide={() => {
                        this.setState({condModalEmailSuccess: !this.state.condModalEmailSuccess});
                    }}
                    txtdescription={"¡Información enviada correctamente!"}
                />
                <AlertModal
                    show={this.state.condAlertModal}
                    onHide={() => {
                        this.setState({condAlertModal: !this.state.condAlertModal});
                    }}
                    txtdescription={this.state.txtAlertModal}
                    txttitle={"Información"}
                />
            </div>
        );
    }
}

export default FinancialGoalTool;
