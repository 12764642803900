import React, {Component} from "react";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import Col from "react-bootstrap/Col";
import Title from "../components/Title";
import AlertModal from "../components/AlertModal";
import {getCatalogDescendants} from "../actions/BudgetTool";
import BudgetPanel from "../components/BudgetPanel";
import {Redirect} from "react-router-dom";
import LoadingModal from "../components/LoadingModal";
import ErrorModal from "../components/ErrorModal";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Chart from "../components/Chart";
import DescriptionModal from "../components/DescriptionModal";
import {sendMail} from "../actions/Global";
import EmailModal from "../components/EmailModal";
import SuccessModal from "../components/SuccessModal";
import ExportButton from "../components/ExportButton";

class FinancialBudgetTool extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            toolTitle: "",
            budgetSteps: [],
            condAlertModal: false,
            txtAlertModal: false,
            description: "",
            frequenciesData: null,
            condModal: false,
            loading: true,
            condError: false,
            redirect: null,
            totalMonthlyExpenses: 0,
            totalYearlyExpenses: 0,
            monthlyBalance: 0,
            yearlyBalance: 0,
            alertsResult: [
                {
                    text: "¡Puedes ahorrar más del 10%! El total del presupuesto de fin de mes es mayor al 10% de gastos.",
                    activeMonthly: false,
                    activeYearly: false,
                    activeClass: "back-green",
                    color: "green"
                },
                {
                    text: "¡No se descuide! La diferencia entre los ingresos y los gastos presupuestarios no se supera el 10%",
                    activeMonthly: false,
                    activeYearly: false,
                    activeClass: "back-yellow",
                    color: "yellow"
                },
                {
                    text: "¡Alerta! Sus gastos son mayores a sus ingresos, por lo tanto debe tener cuidado y revisar la situación de tu presupuesto.",
                    activeMonthly: false,
                    activeYearly: false,
                    activeClass: "back-red",
                    color: "red"
                }
            ],
            condResult: false,
            charts: {
                debtLevelPie: {
                    type: "pie",
                    options: {
                        title: {
                            text: ""
                        },
                        legend: {
                            layout: "vertical",
                            align: "right",
                            verticalAlign: "middle",
                            itemMarginTop: 10,
                            itemMarginBottom: 10
                        },
                        series: []
                    }
                },
                debtLevelColumn: {
                    type: "column",
                    options: {
                        title: {
                            text: ""
                        },
                        yAxis: {
                            title: {
                                text: "Monto"
                            },
                            labels: {
                                // eslint-disable-next-line
                                format: "${value}"
                            }
                        },
                        series: []
                    }
                }
            },
            emailAddress: "",
            condEmailModal: false,
            attachmentId: null,
            textError: "Servicio no disponible por el momento, intente más tarde...",
            condModalEmailSuccess: false,
            condRedirect: false,
        };
    }

    chatsIng: object[] = [];

    async componentDidMount() {
        let condError = false;
        try {
            let toolData: any = await getCatalogDescendants(39);
            if (!toolData.message)
                this.setState({
                    description: toolData.jsonData.description,
                    condModal: true,
                    attachmentId: toolData.jsonData.attachment
                });
            else
                condError = true;
            //get frequencies data
            let frequenciesData: any = await getCatalogDescendants(33);
            if (!toolData.message) {
                toolData.children.forEach((value: any, index: number) => {
                    toolData.children[index]["totalMonthlyAmount"] = 0;
                    toolData.children[index]["totalYearlyAmount"] = 0;
                    value.children.forEach((valueChild: any, indexChild: number) => {
                        toolData.children[index].children[indexChild]["newReg"] = false;
                        toolData.children[index].children[indexChild]["frequency"] = "Diaria";
                        toolData.children[index].children[indexChild]["amount"] = 0;
                        toolData.children[index].children[indexChild]["monthlyAmount"] = 0;
                        toolData.children[index].children[indexChild]["yearlyAmount"] = 0;
                    });
                });
                toolData.children.push({
                    id: null,
                    name: "7. ANÁLISIS DE PRESUPUESTO",
                });
                this.setState({
                    toolTitle: toolData.name,
                    budgetSteps: toolData.children,
                    frequenciesData: frequenciesData.children
                });
            } else {
                condError = true;
            }
        } catch (error) {
            condError = true;
        }
        this.setState({condError, loading: false, condRedirect: condError});
    }

    async _sendMail() {
        let tables: any = [];
        let resumen_table: any = [];
        const {budgetSteps, yearlyBalance, monthlyBalance} = this.state;

        budgetSteps.forEach((step: any) => {
            if (step.id) {
                let dataAux: any = [];
                let stepAux: any = {
                    "title": step.name,
                    "total": {
                        "yearly": step.totalYearlyAmount,
                        "monthly": step.totalMonthlyAmount
                    }
                };
                step.children.forEach((stepItem: any) => {
                    dataAux.push({
                        "label": stepItem.name,
                        "amount": stepItem.amount,
                        "yearly": stepItem.monthlyAmount,
                        "monthly": stepItem.yearlyAmount,
                        "frecuency": stepItem.frequency
                    });
                });
                stepAux["data"] = dataAux;
                tables.push(stepAux);
            }
        });
        //incomes
        budgetSteps[0].children.forEach((income: any) => {
            resumen_table.push({
                "type": "normal",
                "label": income.name,
                "yearly": isFinite(income.yearlyAmount) ? parseFloat(income.yearlyAmount).toFixed(2) : "0.00",
                "monthly": isFinite(income.monthlyAmount) ? parseFloat(income.monthlyAmount).toFixed(2) : "0.00",
                "yearlyPercent": isFinite(income.yearlyAmount) && budgetSteps[0].totalYearlyAmount > 0 ? (parseFloat(income.yearlyAmount) * 100 / this.state.budgetSteps[0].totalYearlyAmount).toFixed(2) : "0.00",
                "monthlyPercent": isFinite(income.monthlyAmount) && budgetSteps[0].totalMonthlyAmount > 0 ? (parseFloat(income.monthlyAmount) * 100 / this.state.budgetSteps[0].totalMonthlyAmount).toFixed(2) : "0.00"
            });
        });
        resumen_table.push({
            "type": "total",
            "label": "INGRESOS",
            "yearly": isFinite(budgetSteps[0].totalYearlyAmount) ? parseFloat(budgetSteps[0].totalYearlyAmount).toFixed(2) : "0.00",
            "monthly": isFinite(budgetSteps[0].totalMonthlyAmount) ? parseFloat(budgetSteps[0].totalMonthlyAmount).toFixed(2) : "0.00",
            "yearlyPercent": "100.00",
            "monthlyPercent": "100.00"
        });
        //expenses
        budgetSteps.forEach((tool: any, toolIndex: number) => {
            if (toolIndex !== 0 && tool.id)
                resumen_table.push({
                    "type": "normal",
                    "label": tool.name,
                    "yearly": isFinite(tool.totalYearlyAmount) ? parseFloat(tool.totalYearlyAmount).toFixed(2) : "0.00",
                    "monthly": isFinite(tool.totalMonthlyAmount) ? parseFloat(tool.totalMonthlyAmount).toFixed(2) : "0.00",
                    "yearlyPercent": isFinite(tool.totalYearlyAmount) && this.state.totalYearlyExpenses > 0 ? (parseFloat(tool.totalYearlyAmount) * 100 / this.state.totalYearlyExpenses).toFixed(2) : "0.00",
                    "monthlyPercent": isFinite(tool.totalMonthlyAmount) && this.state.totalMonthlyExpenses > 0 ? (parseFloat(tool.totalMonthlyAmount) * 100 / this.state.totalMonthlyExpenses).toFixed(2) : "0.00"
                });
        });
        resumen_table.push({
            "type": "total",
            "label": "GASTOS",
            "yearly": isFinite(this.state.totalYearlyExpenses) ? parseFloat(this.state.totalYearlyExpenses).toFixed(2) : "0.00",
            "monthly": isFinite(this.state.totalMonthlyExpenses) ? parseFloat(this.state.totalMonthlyExpenses).toFixed(2) : "0.00",
            "yearlyPercent": "100.00",
            "monthlyPercent": "100.00"
        });

        let dataForm = {
            "title": this.state.toolTitle,
            "tables": tables,
            charts: this.chatsIng,
            "balance": {
                "yearly": isFinite(yearlyBalance) ? parseFloat(yearlyBalance).toFixed(2) : "0.00",
                "monthly": isFinite(monthlyBalance) ? parseFloat(monthlyBalance).toFixed(2) : "0.00"
            },
            "semaphore": {
                "yearly": this._getColorBySemaphoreType("yearly"),
                "monthly": this._getColorBySemaphoreType("monthly")
            },
            resumen_table
        };
        let body = {
            "to": this.state.emailAddress,
            "params": {
                "name": this.state.toolTitle,
                "description": this.state.description
            },
            "attachmentsParams": [
                {
                    "id": this.state.attachmentId,
                    "params": dataForm
                }
            ]
        };
        try {
            this.setState({condEmailModal: false, loading: true});
            await sendMail(body);
            this.setState({loading: false, condModalEmailSuccess: true});
        } catch (e) {
            this.setState({
                textError: "Ocurrió un error al enviar la información, intente nuevamente.",
                condError: true,
                loading: false,
                condRedirect: false
            });
        }
    }

    _getColorBySemaphoreType = (type: any) => {
        let color = null;
        this.state.alertsResult.forEach((value: any) => {
            if ((type === "yearly" && value.activeYearly) || (type === "monthly" && value.activeMonthly))
                color = value.color;
        });
        return color;
    };

    _handleInputChange = (inputToolIndex: number, inputRowIndex: number, e: any, name: any) => {
        let budgetStepsAux = this.state.budgetSteps;
        let toolAux = budgetStepsAux[inputToolIndex];
        let totalMonthlyExpenses = 0;
        let totalYearlyExpenses = 0;
        let monthlyBalance: number;
        let yearlyBalance: number;
        //totals reset
        budgetStepsAux[inputToolIndex].totalMonthlyAmount = 0;
        budgetStepsAux[inputToolIndex].totalYearlyAmount = 0;
        //calculation
        toolAux.children.forEach((rowData: any, rowIndex: any) => {
            if (inputRowIndex === rowIndex) {
                budgetStepsAux[inputToolIndex].children[rowIndex][name] = e.target.value;
                //calculate amounts
                let amountAux = budgetStepsAux[inputToolIndex].children[rowIndex].amount;
                if (isFinite(amountAux) && amountAux.toString().trim() !== "") {
                    //get calculations params
                    let calculationParams: any = this._getFrequencyCalcParams(budgetStepsAux[inputToolIndex].children[rowIndex].frequency);
                    //monthly
                    if (calculationParams && calculationParams.monthly) {
                        if (calculationParams.monthly.operation === "*")
                            budgetStepsAux[inputToolIndex].children[rowIndex].monthlyAmount = parseFloat(amountAux) * parseFloat(calculationParams.monthly.value);
                        if (calculationParams.monthly.operation === "/")
                            budgetStepsAux[inputToolIndex].children[rowIndex].monthlyAmount = parseFloat(amountAux) / parseFloat(calculationParams.monthly.value);
                    } else {
                        budgetStepsAux[inputToolIndex].children[rowIndex].monthlyAmount = amountAux;
                    }
                    //yearly
                    if (calculationParams && calculationParams.yearly) {
                        if (calculationParams.yearly.operation === "*")
                            budgetStepsAux[inputToolIndex].children[rowIndex].yearlyAmount = parseFloat(amountAux) * parseFloat(calculationParams.yearly.value);
                        if (calculationParams.yearly.operation === "/")
                            budgetStepsAux[inputToolIndex].children[rowIndex].yearlyAmount = parseFloat(amountAux) / parseFloat(calculationParams.yearly.value);
                    } else {
                        budgetStepsAux[inputToolIndex].children[rowIndex].yearlyAmount = amountAux;
                    }
                } else {
                    budgetStepsAux[inputToolIndex].children[rowIndex].monthlyAmount = 0;
                    budgetStepsAux[inputToolIndex].children[rowIndex].yearlyAmount = 0;
                }
            }
            budgetStepsAux[inputToolIndex].totalMonthlyAmount += parseFloat(budgetStepsAux[inputToolIndex].children[rowIndex].monthlyAmount);
            budgetStepsAux[inputToolIndex].totalYearlyAmount += parseFloat(budgetStepsAux[inputToolIndex].children[rowIndex].yearlyAmount);
        });
        //update total for resume
        budgetStepsAux.forEach((toolTemp: any, toolIndex: number) => {
            if (toolIndex !== 0 && toolTemp.id) {
                totalMonthlyExpenses += toolTemp.totalMonthlyAmount;
                totalYearlyExpenses += toolTemp.totalYearlyAmount;
            }
        });
        monthlyBalance = budgetStepsAux[0].totalMonthlyAmount - totalMonthlyExpenses;
        yearlyBalance = budgetStepsAux[0].totalYearlyAmount - totalYearlyExpenses;
        this.setState({
            budgetSteps: budgetStepsAux,
            totalMonthlyExpenses,
            totalYearlyExpenses,
            condResult: false,
            monthlyBalance,
            yearlyBalance
        });
    };

    _getFrequencyCalcParams = (name: any) => {
        let result = null;
        this.state.frequenciesData.forEach((value: any) => {
            if (value.name === name)
                result = value.jsonData;
        });
        return result;
    };

    _addRow = (inputToolIndex: number) => {
        let budgetStepsAux = this.state.budgetSteps;
        budgetStepsAux[inputToolIndex].children.push({
            newReg: true,
            name: "",
            frequency: "Diaria",
            amount: 0,
            monthlyAmount: 0,
            yearlyAmount: 0
        });
        this.setState({budgetSteps: budgetStepsAux});
    };

    _calculateResult = () => {
        const {
            monthlyBalance,
            yearlyBalance,
            alertsResult,
            totalMonthlyExpenses,
            totalYearlyExpenses,
            charts
        } = this.state;
        let alertsTemp = alertsResult;
        let condResult = false;
        let totalMonthlyIncomes = isFinite(this.state.budgetSteps[0].totalMonthlyAmount) ? parseFloat(this.state.budgetSteps[0].totalMonthlyAmount) : 0;
        let totalYearlyIncomes = isFinite(this.state.budgetSteps[0].totalYearlyAmount) ? parseFloat(this.state.budgetSteps[0].totalYearlyAmount) : 0;
        //check option 1
        if (monthlyBalance > (totalMonthlyIncomes * 0.01)) {
            alertsTemp[0].activeMonthly = true;
            condResult = true;
        } else {
            alertsTemp[0].activeMonthly = false;
        }
        if (yearlyBalance > (totalYearlyIncomes * 0.01)) {
            alertsTemp[0].activeYearly = true;
            condResult = true;
        } else {
            alertsTemp[0].activeYearly = false;
        }
        //check option 2
        if (monthlyBalance >= 0 && monthlyBalance < (totalMonthlyIncomes * 0.1)) {
            alertsTemp[1].activeMonthly = true;
            condResult = true;
        } else {
            alertsTemp[1].activeMonthly = false;
        }
        if (yearlyBalance >= 0 && yearlyBalance < (totalYearlyIncomes * 0.1)) {
            alertsTemp[1].activeYearly = true;
            condResult = true;
        } else {
            alertsTemp[1].activeYearly = false;
        }
        //check option 3
        if (totalMonthlyExpenses > totalMonthlyIncomes) {
            alertsTemp[2].activeMonthly = true;
            condResult = true;
        } else {
            alertsTemp[2].activeMonthly = false;
        }
        if (totalYearlyExpenses > totalYearlyIncomes) {
            alertsTemp[2].activeYearly = true;
            condResult = true;
        } else {
            alertsTemp[2].activeYearly = false;
        }
        const {
            debtLevelPieSeries,
            debtLevelColumnSeries
        } = this.processChats(this.state.budgetSteps, totalMonthlyIncomes, totalMonthlyExpenses, monthlyBalance);
        charts.debtLevelPie.options.series = [debtLevelPieSeries];
        charts.debtLevelColumn.options.series = [debtLevelColumnSeries];
        this.setState({alertsResult: alertsTemp, condResult, charts});
    };

    processChats(expensesData: any, incomeTotal: any, expensesTotal: any, monthlyBalance: any) {
        const debtLevelPieSeries: any = {
            name: "Gastos",
            colorByPoint: true,
            data: []
        };
        const debtLevelColumnSeries: any = {
            name: "Monto",
            colorByPoint: true,
            data: [
                {
                    name: "INGRESOS",
                    y: incomeTotal || 0,
                },
                {
                    name: "GASTOS",
                    y: expensesTotal || 0,
                    color: "red"
                },
                {
                    name: "BALANCE GENERAL",
                    y: monthlyBalance || 0,
                }
            ]
        };
        expensesData.forEach((toolTemp: any, toolIndex: number) => {
            if (toolIndex !== 0 && toolTemp.id) {
                debtLevelPieSeries.data.push({
                    name: toolTemp.name,
                    y: toolTemp.totalMonthlyAmount * 100 / expensesTotal
                });
            }
        });
        return {debtLevelPieSeries, debtLevelColumnSeries};
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>;
        }
        return (
            <div className={"row"}>
                {this.state.toolTitle !== "" ? (
                    <div className={"col"}>
                        <Title txtTitle={this.state.toolTitle} handleClick={() => {
                            this.setState({redirect: "/"});
                        }}/>
                        <Card className={"mt-3 sb-card-content"}>
                            <Card.Body>
                                <div className={"row"}>
                                    <div className={"col"}>
                                        <Tab.Container id="left-tabs-example" defaultActiveKey={0}>
                                            <Row>
                                                <Col xs={12} sm={12} md={3}>
                                                    <Nav variant="pills" className="flex-column">
                                                        {this.state.budgetSteps.map((step: any, index: number) => {
                                                            return (
                                                                <Nav.Item key={index}>
                                                                    <Nav.Link eventKey={index}>{step.name}</Nav.Link>
                                                                </Nav.Item>);
                                                        })}
                                                    </Nav>
                                                </Col>
                                                <Col xs={12} sm={12} md={9}>
                                                    <Tab.Content>
                                                        {this.state.budgetSteps.map((step: any, index: number) => {
                                                            return (
                                                                <Tab.Pane key={index} eventKey={index}>
                                                                    {step.id ?
                                                                        <BudgetPanel data={step}
                                                                                     frequenciesData={this.state.frequenciesData}
                                                                                     handleInputChange={(inputRowIndex: number, e: any, name: any) => {
                                                                                         this._handleInputChange(index, inputRowIndex, e, name);
                                                                                     }}
                                                                                     addRow={() => {
                                                                                         this._addRow(index);
                                                                                     }}
                                                                        />
                                                                        :
                                                                        <Row>
                                                                            <Col xs={12}>
                                                                                <Table striped bordered hover
                                                                                       size={"sm"}
                                                                                       responsive={"xl"}>
                                                                                    <thead>
                                                                                    <tr className={"text-center"}>
                                                                                        <th>RESUMEN PRESUPUESTARIO</th>
                                                                                        <th>MONTO MENSUAL ($)</th>
                                                                                        <th>%</th>
                                                                                        <th>MONTO ANUAL ($)</th>
                                                                                        <th>%</th>
                                                                                    </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                    {this.state.budgetSteps[0].children.map((income: any, incomeIndex: number) => (
                                                                                        <tr key={incomeIndex}>
                                                                                            <td>{income.name}</td>
                                                                                            <td className={"text-right"}>{isFinite(income.monthlyAmount) ? parseFloat(income.monthlyAmount).toFixed(2) : "0.00"}</td>
                                                                                            <td className={"text-right"}>{isFinite(income.monthlyAmount) && this.state.budgetSteps[0].totalMonthlyAmount > 0 ? (parseFloat(income.monthlyAmount) * 100 / this.state.budgetSteps[0].totalMonthlyAmount).toFixed(2) : "0.00"}</td>
                                                                                            <td className={"text-right"}>{isFinite(income.yearlyAmount) ? parseFloat(income.yearlyAmount).toFixed(2) : "0.00"}</td>
                                                                                            <td className={"text-right"}>{isFinite(income.yearlyAmount) && this.state.budgetSteps[0].totalYearlyAmount > 0 ? (parseFloat(income.yearlyAmount) * 100 / this.state.budgetSteps[0].totalYearlyAmount).toFixed(2) : "0.00"}</td>
                                                                                        </tr>
                                                                                    ))}
                                                                                    <tr className={"tr-bold"}>
                                                                                        <td>INGRESOS</td>
                                                                                        <td className={"text-right"}>{isFinite(this.state.budgetSteps[0].totalMonthlyAmount) ? parseFloat(this.state.budgetSteps[0].totalMonthlyAmount).toFixed(2) : "0.00"}</td>
                                                                                        <td className={"text-right"}>100.00</td>
                                                                                        <td className={"text-right"}>{isFinite(this.state.budgetSteps[0].totalYearlyAmount) ? parseFloat(this.state.budgetSteps[0].totalYearlyAmount).toFixed(2) : "0.00"}</td>
                                                                                        <td className={"text-right"}>100.00</td>
                                                                                    </tr>
                                                                                    {this.state.budgetSteps.map((tool: any, toolIndex: number) => {
                                                                                        if (toolIndex !== 0)
                                                                                            return (
                                                                                                <tr key={toolIndex}>
                                                                                                    <td>{tool.name}</td>
                                                                                                    <td className={"text-right"}>{isFinite(tool.totalMonthlyAmount) ? parseFloat(tool.totalMonthlyAmount).toFixed(2) : "0.00"}</td>
                                                                                                    <td className={"text-right"}>{isFinite(tool.totalMonthlyAmount) && this.state.totalMonthlyExpenses > 0 ? (parseFloat(tool.totalMonthlyAmount) * 100 / this.state.totalMonthlyExpenses).toFixed(2) : "0.00"}</td>
                                                                                                    <td className={"text-right"}>{isFinite(tool.totalYearlyAmount) ? parseFloat(tool.totalYearlyAmount).toFixed(2) : "0.00"}</td>
                                                                                                    <td className={"text-right"}>{isFinite(tool.totalYearlyAmount) && this.state.totalYearlyExpenses > 0 ? (parseFloat(tool.totalYearlyAmount) * 100 / this.state.totalYearlyExpenses).toFixed(2) : "0.00"}</td>
                                                                                                </tr>
                                                                                            );
                                                                                        else return (
                                                                                            <tr key={toolIndex}/>);
                                                                                    })}
                                                                                    <tr className={"tr-bold"}>
                                                                                        <td>GASTOS</td>
                                                                                        <td className={"text-right"}>{isFinite(this.state.totalMonthlyExpenses) ? parseFloat(this.state.totalMonthlyExpenses).toFixed(2) : "0.00"}</td>
                                                                                        <td className={"text-right"}>100.00</td>
                                                                                        <td className={"text-right"}>{isFinite(this.state.totalYearlyExpenses) ? parseFloat(this.state.totalYearlyExpenses).toFixed(2) : "0.00"}</td>
                                                                                        <td className={"text-right"}>100.00</td>
                                                                                    </tr>
                                                                                    </tbody>
                                                                                </Table>
                                                                                <Button variant="success"
                                                                                        className={"mt-2"}
                                                                                        type="button"
                                                                                        onClick={this._calculateResult}>Obtener
                                                                                    Reporte</Button>
                                                                                {this.state.condResult ?
                                                                                    <div>
                                                                                        <hr/>
                                                                                        <h6 className={"text-bold"}>BALANCE
                                                                                            GENERAL:</h6>
                                                                                        <Row>
                                                                                            <Col xs={12} sm={5}>
                                                                                                <h6 className={"text-bold"}>Monto
                                                                                                    Mensual:</h6>
                                                                                            </Col>
                                                                                            <Col xs={12} sm={3}>
                                                                                                <h5>
                                                                                                    <Badge
                                                                                                        variant="primary">${isFinite(this.state.monthlyBalance) ? parseFloat(this.state.monthlyBalance).toFixed(2) : "0.00"}</Badge>
                                                                                                </h5>
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <Row>
                                                                                            <Col xs={12} sm={5}>
                                                                                                <h6 className={"text-bold"}>Monto
                                                                                                    Anual:</h6>
                                                                                            </Col>
                                                                                            <Col xs={12} sm={3}>
                                                                                                <h5><Badge
                                                                                                    variant="primary">${isFinite(this.state.yearlyBalance) ? parseFloat(this.state.yearlyBalance).toFixed(2) : "0.00"}</Badge>
                                                                                                </h5>
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <hr/>
                                                                                        <Card>
                                                                                            <Card.Header as="h5"
                                                                                                         className={"text-center"}>
                                                                                                Semáforo de Alerta de
                                                                                                Presupuesto
                                                                                                Mensual
                                                                                            </Card.Header>
                                                                                            <Card.Body>
                                                                                                <Row
                                                                                                    className={"row-text-bold"}>
                                                                                                    <Col xs={6}>
                                                                                                        <p>DESCRIPCIÓN</p>
                                                                                                    </Col>
                                                                                                    <Col xs={3}>
                                                                                                        <p>MENSUAL</p>
                                                                                                    </Col>
                                                                                                    <Col xs={3}>
                                                                                                        <p>ANUAL</p>
                                                                                                    </Col>
                                                                                                </Row>
                                                                                                {this.state.alertsResult.map((value: any, index: number) => (
                                                                                                    <Row key={index}>
                                                                                                        <Col xs={12}>
                                                                                                            <hr/>
                                                                                                        </Col>
                                                                                                        <Col xs={6}>
                                                                                                            <p>{value.text}</p>
                                                                                                        </Col>
                                                                                                        <Col xs={3}>
                                                                                                            <div
                                                                                                                className={"item-result-circle " + (value.activeMonthly ? value.activeClass : "")}>
                                                                                                            </div>
                                                                                                        </Col>
                                                                                                        <Col xs={3}>
                                                                                                            <div
                                                                                                                className={"item-result-circle " + (value.activeYearly ? value.activeClass : "")}>
                                                                                                            </div>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                ))}
                                                                                            </Card.Body>
                                                                                        </Card>
                                                                                        <hr/>
                                                                                        <Row>
                                                                                            <Col>
                                                                                                <Chart
                                                                                                    type={this.state.charts.debtLevelPie.type}
                                                                                                    callback={(img: any) => this.getReports(img, "debtLevelPie")}
                                                                                                    options={this.state.charts.debtLevelPie.options}/>
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <Row>
                                                                                            <Col>
                                                                                                <Chart
                                                                                                    type={this.state.charts.debtLevelColumn.type}
                                                                                                    callback={(img: any) => this.getReports(img, "debtLevelColumn")}
                                                                                                    options={this.state.charts.debtLevelColumn.options}/>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </div>
                                                                                    : <div/>}
                                                                            </Col>
                                                                            <Col xs={12}>
                                                                                <Row className={"mt-4"}>
                                                                                    <Col>
                                                                                        <div className={"text-center"}>
                                                                                            <ExportButton
                                                                                                onClick={() => {
                                                                                                    if (this.state.condResult)
                                                                                                        this.setState({condEmailModal: true});
                                                                                                    else
                                                                                                        this.setState({
                                                                                                            condAlertModal: true,
                                                                                                            txtAlertModal: "Ingrese su información para realizar la exportación."
                                                                                                        });
                                                                                                }}/>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>
                                                                        </Row>
                                                                    }
                                                                </Tab.Pane>);
                                                        })}
                                                    </Tab.Content>
                                                </Col>
                                            </Row>
                                        </Tab.Container>
                                    </div>
                                </div>
                            </Card.Body></Card>
                    </div>
                ) : <div/>}
                <DescriptionModal
                    show={this.state.condModal}
                    onHide={() => {
                        this.setState({condModal: !this.state.condModal});
                    }}
                    txtdescription={this.state.description}
                />
                <LoadingModal
                    show={this.state.loading}
                    onHide={() => {
                        this.setState({loading: !this.state.loading});
                    }}
                />
                <ErrorModal
                    show={this.state.condError}
                    onHide={() => {
                        this.setState({
                            condError: !this.state.condError,
                            redirect: this.state.condRedirect ? "/" : null,
                        });
                    }}
                    txtdescription={this.state.textError}
                />
                <EmailModal
                    show={this.state.condEmailModal}
                    value={this.state.emailAddress}
                    onHide={() => {
                        this.setState({condEmailModal: false});
                    }}
                    onChange={(e: any) => {
                        this.setState({emailAddress: e.target.value});
                    }}
                    handleSubmit={(event: any) => {
                        event.preventDefault();
                        this._sendMail();
                    }}
                    txtdescription={this.state.description}
                />
                <SuccessModal
                    show={this.state.condModalEmailSuccess}
                    onHide={() => {
                        this.setState({condModalEmailSuccess: !this.state.condModalEmailSuccess});
                    }}
                    txtdescription={"¡Información enviada correctamente!"}
                />
                <AlertModal
                    show={this.state.condAlertModal}
                    onHide={() => {
                        this.setState({condAlertModal: !this.state.condAlertModal});
                    }}
                    txtdescription={this.state.txtAlertModal}
                    txttitle={"Información"}
                />
            </div>
        );
    }

    getReports = (chart: any, type: string): void => {
        if (type === "debtLevelColumn")
            this.chatsIng[1] = {graph: chart};
        else
            this.chatsIng[0] = {graph: chart};
    };
}

export default FinancialBudgetTool;
