import {API_URL} from "../constants/constants"

export const getScheduledSavings = (data: any) => {
    return new Promise((resolve, reject) => {
        return fetch(API_URL + "tool/savingCalculator", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsYWxhIjoibGVsZSIsImlhdCI6MTYwNDE5Nzg2M30.q5KFfzMqBDfiO5QTCTGbYmKfo6sOxrp8rxZrX1UIWbU"
            },
            body: JSON.stringify(data)
        }).then(response => response.json()).then(responseJson => {
            resolve(responseJson)
        }).catch(error => {
            reject(error)
        });
    });
};
