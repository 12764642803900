import React from "react";
import Button from "react-bootstrap/Button";

const ExportButton = (props: any) => {
    return (
        <Button {...props} size={props.size || "lg"} onClick={props.onClick}
                variant={props.variant || "secondary"}><i className="fa fa-envelope-o"/> {props.title || "ENVIAR"}
        </Button>
    );
};

export default ExportButton;
