import BudgetTool from "../views/BudgetTool";
import FinancialGoalTool from "../views/FinancialGoalTool";
import OptimizeExpensesTool from "../views/OptimizeExpensesTool";
import OnBoarding from "../views/OnBoarding";
import CreditTool from "../views/CreditTool";
import DebtLevel from "../views/DebtLevel";
import CreditCard from "../views/CreditCard";
import MortgageLoanTool from "../views/MortgageLoanTool";
import SavingsPolicyTool from "../views/SavingsPolicyTool";
import SavingCalculatorTool from "../views/SavingCalculatorTool";
import FinancialBudgetTool from "../views/FinancialBudgetTool";
import ShoppingNeed from "../views/ShoppingNeed";
import RetirementNeed from "../views/RetirementNeed";
import EntrepreneurNeed from "../views/EntrepreneurNeed";
import StudentNeed from "../views/StudentNeed";
import HouseholdFinancesNeed from "../views/HouseholdFinancesNeed";

export const routes = [
    //needs
    {
        name: "shopping-need",
        path: "/necesidad/compras",
        component: ShoppingNeed
    },
    {
        name: "retirement-need",
        path: "/necesidad/jubilacion",
        component: RetirementNeed
    },
    {
        name: "entrepreneur-need",
        path: "/necesidad/emprendedor",
        component: EntrepreneurNeed
    },
    {
        name: "student-need",
        path: "/necesidad/estudiante",
        component: StudentNeed
    },
    {
        name: "household-finances-need",
        path: "/necesidad/finanzas-hogar",
        component: HouseholdFinancesNeed
    },
    //tools
    {
        name: "credit-card-tool",
        path: "/herramienta/tarjeta-credito",
        component: CreditCard
    },
    {
        name: "savings-policy-tool",
        path: "/herramienta/poliza",
        component: SavingsPolicyTool
    },
    {
        name: "debt-level-tool",
        path: "/herramienta/nivel-endeudamiento",
        component: DebtLevel
    },
    {
        name: "budget-tool",
        path: "/herramienta/presupuesto",
        component: BudgetTool
    },
    {
        name: "optimize-expenses-tool",
        path: "/herramienta/optimizacion-gastos",
        component: OptimizeExpensesTool
    },
    {
        name: "financial-goal-tool",
        path: "/herramienta/metas-financieras",
        component: FinancialGoalTool
    },
    {
        name: "credit-tool",
        path: "/herramienta/credito",
        component: CreditTool
    },
    {
        name: "mortgage-loan-tool",
        path: "/herramienta/prestamo-hipotecario",
        component: MortgageLoanTool
    },
    {
        name: "saving-calculator-tool",
        path: "/herramienta/calculadora-ahorro",
        component: SavingCalculatorTool
    },
    {
        name: "financial-budget-tool",
        path: "/herramienta/presupuesto-financiero",
        component: FinancialBudgetTool
    },
    {
        name: "on-boarding",
        path: "/",
        component: OnBoarding
    },
];
