import React from "react";
import Form from "react-bootstrap/cjs/Form";
import InputGroup from "react-bootstrap/cjs/InputGroup";
import Row from "react-bootstrap/cjs/Row";
import PropTypes from "prop-types";

const NumberInput = ({name, prepend, append, title, titleClass, inputClass, handleChangeInput, placeholder}: any) => {
    return (
        <Form.Group as={Row}>
            <Form.Label column className={titleClass || "col-8"}>{title}</Form.Label>
            <InputGroup className={inputClass || "col-4"}>
                {prepend ?
                    <InputGroup.Prepend>
                        <InputGroup.Text>{prepend}</InputGroup.Text>
                    </InputGroup.Prepend>
                    : <div/>}
                <Form.Control
                    type="number"
                    aria-describedby="inputGroupPrepend"
                    name={name}
                    onChange={handleChangeInput}
                    placeholder={placeholder || ""}
                />
                {append ?
                    <InputGroup.Append>
                        <InputGroup.Text>{append}</InputGroup.Text>
                    </InputGroup.Append>
                    : <div/>}
            </InputGroup>
        </Form.Group>
    );
};
NumberInput.propTypes = {
    name: PropTypes.string,
    prepend: PropTypes.string,
    append: PropTypes.string,
    title: PropTypes.string,
    titleClass: PropTypes.string,
    inputClass: PropTypes.string,
    handleChangeInput: PropTypes.func,
    placeholder: PropTypes.string,
};
export default NumberInput;
