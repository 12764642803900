import React, {Component} from "react";

interface ItemToolProps {
    item: item,
    handle: (item: item) => void;
}


type item = {
    color: string;
    title: string;
    routeName: string;
    icon: string;
}

class ItemTool extends Component<ItemToolProps, any> {
    render() {
        const {item, handle} = this.props;
        return <div
            className="col-md-6 col-sm-6 col-lg-3 align-items-center item-container mt-3 mb-3"
            onClick={() => handle(item)}>
            <div className={"item-tool-icon " + item.color}>
                <i className={item.icon}/>
            </div>
            <h6 className="item-name item-title">{item.title}</h6>
        </div>;
    }
}

export default ItemTool;