import React, {Component} from "react";
import Card from "react-bootstrap/cjs/Card";
import Row from "react-bootstrap/cjs/Row";
import Col from "react-bootstrap/cjs/Col";
import Form from "react-bootstrap/cjs/Form";
import Alert from "react-bootstrap/cjs/Alert";
import DescriptionModal from "../components/DescriptionModal";
import NumberInput from "../components/NumberInput";
import {SAVINGS_POLICY_RESULT} from "../constants/constants";
import {getCatalogDescendants} from "../actions/BudgetTool";
import {Redirect} from "react-router-dom";
import LoadingModal from "../components/LoadingModal";
import ErrorModal from "../components/ErrorModal";
import Title from "../components/Title";
import Image from "react-bootstrap/Image";
import {sendMail} from "../actions/Global";
import EmailModal from "../components/EmailModal";
import SuccessModal from "../components/SuccessModal";
import AlertModal from "../components/AlertModal";
import ExportButton from "../components/ExportButton";

const fullSquirrel = require("../assets/images/design/full-squirrel.png")

class SavingsPolicyTool extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            toolTitle: "",
            description: "",
            inputs: {
                amount: {title: "Monto", value: ""},
                interest: {title: "Interés anual", value: ""},
                term: {title: "Plazo", value: ""}
            },
            total: {
                interestGained: {title: "Interés ganado", value: 0},
                totalReceived: {title: "Total recibido", value: 0},
                result: ""
            },
            condModal: false,
            loading: true,
            condError: false,
            redirect: null,
            emailAddress: '',
            condEmailModal: false,
            attachmentId: null,
            textError: "Servicio no disponible por el momento, intente más tarde...",
            condModalEmailSuccess: false,
            txtAlertModal: '',
            condRedirect: false,
            condAlertModal: false
        };
    }

    async componentDidMount() {
        let condError = false;
        try {
            let budgetData: any = await getCatalogDescendants(100);
            if (!budgetData.message)
                this.setState({
                    description: budgetData.jsonData.description,
                    toolTitle: budgetData.name,
                    condModal: true,
                    attachmentId: budgetData.jsonData.attachment
                })
            else
                condError = true
        } catch (error) {
            condError = true
        }
        this.setState({condError, loading: false, condRedirect: condError})
    }

    async _sendMail() {
        const {inputs, total} = this.state;

        let dataForm = {
            "title": this.state.toolTitle,
            "info": {
                "term": inputs.term.value + " días",
                "amount": "$" + inputs.amount.value,
                "anualRate": inputs.interest.value + "%"
            },
            "messages": [
                {
                    "text": total.result,
                    "type": "primary"
                },
                {
                    "text": `<p><strong>${total.interestGained.title}: </strong>$${parseFloat(total.interestGained.value).toFixed(2)}<br><strong>${total.totalReceived.title}: </strong>$${parseFloat(total.totalReceived.value).toFixed(2)}</p>`,
                    "type": "success"
                }
            ]
        };
        let body = {
            "to": this.state.emailAddress,
            "params": {
                "name": this.state.toolTitle,
                "description": this.state.description
            },
            "attachmentsParams": [
                {
                    "id": this.state.attachmentId,
                    "params": dataForm
                }
            ]
        };
        try {
            await this.setState({condEmailModal: false, loading: true})
            await sendMail(body)
            this.setState({loading: false, condModalEmailSuccess: true})
        } catch (e) {
            this.setState({
                textError: "Ocurrió un error al enviar la información, intente nuevamente.",
                condError: true,
                loading: false,
                condRedirect: false
            })
        }
    }

    render() {
        const {inputs: {amount, interest, term}, total} = this.state;
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>
        }
        return <Row>
            {this.state.toolTitle !== "" ? (
                <Col>
                    <Title txtTitle={this.state.toolTitle} handleClick={() => {
                        this.setState({redirect: "/"})
                    }}/>
                    <Card className="mt-3 sb-card-content">
                        <Card.Body>
                            <Col>
                                <Row>
                                    <Col xl={7} lg={7} md={12} sm={12}>
                                        <Form>
                                            <NumberInput
                                                prepend="$"
                                                name="amount"
                                                titleClass={"col-md-6 col-sm-12 col-xs-12"}
                                                inputClass={"col-md-6 col-sm-12 col-xs-12"}
                                                title={amount.title}
                                                handleChangeInput={this.handleChangeInput}
                                                placeholder={"0.00"}
                                            />
                                            <NumberInput
                                                append="%"
                                                name="interest"
                                                titleClass={"col-md-6 col-sm-12 col-xs-12"}
                                                inputClass={"col-md-6 col-sm-12 col-xs-12"}
                                                title={interest.title}
                                                handleChangeInput={this.handleChangeInput}
                                                placeholder={"0.00"}
                                            />
                                            <NumberInput
                                                append="Días"
                                                name="term"
                                                titleClass={"col-md-6 col-sm-12 col-xs-12"}
                                                inputClass={"col-md-6 col-sm-12 col-xs-12"}
                                                title={term.title}
                                                handleChangeInput={this.handleChangeInput}
                                                placeholder={"365"}
                                            />
                                        </Form>
                                    </Col>
                                    <Col xl={5} lg={5} md={12} sm={12}>
                                        <Alert variant={"success"} className="col-12 sb-tool-oe-result">
                                            <div className="d-flex">
                                                <img className="sb-tool-oe-result-icon" alt=""/>
                                                <Row className={"m-0"}>
                                                    <p className="m-0 text-large">
                                                        <strong>{total.interestGained.title}: </strong>
                                                        $ {isFinite(total.interestGained.value) ? parseFloat(total.interestGained.value).toFixed(2) : "0.00"}
                                                    </p>
                                                    <p className="m-0 text-large">
                                                        <strong>{total.totalReceived.title}: </strong>
                                                        $ {isFinite(total.totalReceived.value) ? parseFloat(total.totalReceived.value).toFixed(2) : "0.00"}
                                                    </p>
                                                </Row>
                                            </div>
                                        </Alert>
                                    </Col>
                                </Row>
                                {(total.result !== "" && (parseFloat(amount.value) > 0 && parseFloat(interest.value) > 0 && parseFloat(term.value) > 0)) ? (
                                    <Row className={"mt-4"}>
                                        <Col>
                                            <div className={"container-squirrel-explain"}>
                                                <div className={"container-speech-result-lg"}>
                                                    <p>{total.result}</p>
                                                </div>
                                                <Image src={fullSquirrel} fluid={true}/>
                                            </div>
                                        </Col>
                                    </Row>) : (<Row/>)}
                                <Row className={"mt-4"}>
                                    <Col>
                                        <div className={"text-center"}>
                                            <ExportButton onClick={() => {
                                                if (total.result !== "" && (parseFloat(amount.value) > 0 && parseFloat(interest.value) > 0 && parseFloat(term.value) > 0))
                                                    this.setState({condEmailModal: true})
                                                else
                                                    this.setState({
                                                        condAlertModal: true,
                                                        txtAlertModal: "Ingrese su información para realizar la exportación."
                                                    })
                                            }}/>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Card.Body>
                    </Card>
                </Col>
            ) : <Col/>}
            <DescriptionModal
                show={this.state.condModal}
                onHide={() => {
                    this.setState({condModal: !this.state.condModal})
                }}
                txtdescription={this.state.description}
                heightModal={298}
            />
            <LoadingModal
                show={this.state.loading}
                onHide={() => {
                    this.setState({loading: !this.state.loading})
                }}
            />
            <ErrorModal
                show={this.state.condError}
                onHide={() => {
                    this.setState({
                        condError: !this.state.condError,
                        redirect: this.state.condRedirect ? "/" : null,
                    })
                }}
                txtdescription={this.state.textError}
            />
            <EmailModal
                show={this.state.condEmailModal}
                value={this.state.emailAddress}
                onHide={() => {
                    this.setState({condEmailModal: false})
                }}
                onChange={(e: any) => {
                    this.setState({emailAddress: e.target.value})
                }}
                handleSubmit={(event: any) => {
                    event.preventDefault();
                    this._sendMail()
                }}
                txtdescription={this.state.description}
            />
            <SuccessModal
                show={this.state.condModalEmailSuccess}
                onHide={() => {
                    this.setState({condModalEmailSuccess: !this.state.condModalEmailSuccess})
                }}
                txtdescription={"¡Información enviada correctamente!"}
            />
            <AlertModal
                show={this.state.condAlertModal}
                onHide={() => {
                    this.setState({condAlertModal: !this.state.condAlertModal})
                }}
                txtdescription={this.state.txtAlertModal}
                txttitle={"Información"}
            />
        </Row>;
    }

    handleChangeInput = (element: any) => {
        const {target: {name, value}} = element;
        const {inputs} = this.state;
        inputs[name].value = parseFloat(value) || 0;
        this.setState({inputs, ...this.processResult(inputs)});
    };

    processResult({amount, interest, term}: any) {
        const {total} = this.state;
        total.interestGained.value = ((amount.value * ((interest.value / 100) / 365) * term.value) || 0);
        total.totalReceived.value = amount.value + total.interestGained.value;
        total.result = SAVINGS_POLICY_RESULT.replace("{term}", term.value)
            .replace("{interestGained}", parseFloat(interest.value).toFixed(2))
            .replace("{totalReceived}", parseFloat(total.totalReceived.value).toFixed(2));
        return {total};
    }
}

export default SavingsPolicyTool;
