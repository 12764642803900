import React, {Component} from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Title from "../components/Title";
import InputGroup from "react-bootstrap/InputGroup";
import {getCatalogDescendants} from "../actions/BudgetTool";
import {Redirect} from "react-router-dom";
import LoadingModal from "../components/LoadingModal";
import ErrorModal from "../components/ErrorModal";
import AlertModal from "../components/AlertModal";
import EmailModal from "../components/EmailModal";
import SuccessModal from "../components/SuccessModal";
import {sendMail} from "../actions/Global";
import ExportButton from "../components/ExportButton";

class EntrepreneurNeed extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            toolTitle: "",
            entrepreneurData: [],
            condModal: false,
            loading: true,
            condError: false,
            redirect: null,
            condAlertModal: false,
            txtAlertModal: false,
            result: [],
            currentType: {},
            emailAddress: "",
            condEmailModal: false,
            attachmentId: null,
            textError: "Servicio no disponible por el momento, intente más tarde...",
            condModalEmailSuccess: false,
            condRedirect: false,
        };
    }

    async componentDidMount() {
        let condError = false;
        try {
            let entrepreneurData: any = await getCatalogDescendants(106);
            if (!entrepreneurData.message) {
                this.setState({
                    toolTitle: entrepreneurData.name,
                    condModal: true,
                    entrepreneurData: entrepreneurData.children,
                    attachmentId: entrepreneurData.jsonData.attachment
                });
                this._setCurrentType(entrepreneurData.children[0].id, null);
            } else
                condError = true;
        } catch (error) {
            condError = true;
        }
        this.setState({condError, loading: false, condRedirect: condError});
    }

    _setCurrentType = (id: any, name: any) => {
        this.state.entrepreneurData.forEach((value: any) => {
            if ((id && (value.id === id)) || (name && (value.name === name)))
                this.setState({currentType: value});
        });
    };

    _handleNeedTypeChange = (event: any) => {
        this._setCurrentType(null, event.target.value);
    };

    async _sendMail() {
        let table: any = [];
        const {currentType} = this.state;
        currentType.jsonData.content.data.table.forEach((value: any) => {
            table.push({
                label: value[0],
                value: value[1]
            });
        });
        let dataForm = {
            "title": this.state.toolTitle,
            table
        };
        let body = {
            "to": this.state.emailAddress,
            "params": {
                "name": this.state.toolTitle,
                "description": this.state.description
            },
            "attachmentsParams": [
                {
                    "id": this.state.attachmentId,
                    "params": dataForm
                }
            ]
        };
        try {
            await this.setState({condEmailModal: false, loading: true});
            await sendMail(body);
            this.setState({loading: false, condModalEmailSuccess: true});
        } catch (e) {
            this.setState({
                textError: "Ocurrió un error al enviar la información, intente nuevamente.",
                condError: true,
                loading: false,
                condRedirect: false
            });
        }
    }

    render() {
        const {currentType} = this.state;
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>;
        }
        return (
            <Row>
                {this.state.toolTitle !== "" ? (
                    <Col>
                        <Title txtTitle={this.state.toolTitle} handleClick={() => {
                            this.setState({redirect: "/"});
                        }}/>
                        <Card className={"mt-3 sb-card-content"}>
                            <Card.Body>
                                <Form as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                                        <Form.Group as={Row}>
                                            <Form.Label column sm={5}>
                                                Selecciona tu perfil
                                            </Form.Label>
                                            <Col sm={7}>
                                                <InputGroup>
                                                    <Form.Control type="text" placeholder="0.00"
                                                                  value={"Soy emprendedor"}
                                                                  readOnly
                                                    />
                                                </InputGroup>
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                                        <Form.Group as={Row}>
                                            <Form.Label column sm={5}>
                                                ¿Qué necesidad tienes?
                                            </Form.Label>
                                            <Col sm={7}>
                                                <Form.Control as="select" onChange={this._handleNeedTypeChange}>
                                                    {this.state.entrepreneurData.map((value: any, index: number) =>
                                                        <option key={index}>{value.name}</option>
                                                    )}
                                                </Form.Control>
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                </Form>
                                {currentType.jsonData ?
                                    <Row>
                                        <Col>
                                            <Card className="text-center">
                                                <Card.Header
                                                    className={"text-bold"}>{currentType.jsonData.content.title}</Card.Header>
                                                <Card.Body>
                                                    <Card.Title
                                                        className={"text-bold"}>{currentType.jsonData.content.data.title}</Card.Title>
                                                    <Card.Text>
                                                        {currentType.jsonData.content.data.text || ""}
                                                    </Card.Text>
                                                    {currentType.jsonData.content.data.table ?
                                                        <Table responsive bordered striped>
                                                            <tbody>
                                                            {currentType.jsonData.content.data.table.map((tableData: any, tableIndex: number) => (
                                                                <tr key={tableIndex}>
                                                                    <td className={"text-bold"}>{tableData[0]}</td>
                                                                    <td>{tableData[1]}</td>
                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </Table>
                                                        : <div/>}
                                                    {currentType.jsonData.content.data.button ?
                                                        <Button variant="primary" onClick={() => {
                                                            this.setState({redirect: currentType.id === 136 ? "/herramienta/metas-financieras" : "/herramienta/credito"});
                                                        }}>{currentType.jsonData.content.data.button.text}</Button>
                                                        : (<div/>)}
                                                    {currentType.jsonData.content.data.table ? (
                                                        <Row className={"mt-4"}>
                                                            <Col>
                                                                <div className={"text-center"}>
                                                                    <ExportButton onClick={() => {
                                                                        this.setState({condEmailModal: true});
                                                                    }}/>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    ) : <Row/>}
                                                </Card.Body>
                                                <Card.Footer className="text-muted"/>
                                            </Card>
                                        </Col>
                                    </Row> : <Row/>}
                            </Card.Body>
                        </Card>
                    </Col>
                ) : <div/>}
                <LoadingModal
                    show={this.state.loading}
                    onHide={() => {
                        this.setState({loading: !this.state.loading});
                    }}
                />
                <ErrorModal
                    show={this.state.condError}
                    onHide={() => {
                        this.setState({
                            condError: !this.state.condError,
                            redirect: this.state.condRedirect ? "/" : null,
                        });
                    }}
                    txtdescription={this.state.textError}
                />
                <EmailModal
                    show={this.state.condEmailModal}
                    value={this.state.emailAddress}
                    onHide={() => {
                        this.setState({condEmailModal: false});
                    }}
                    onChange={(e: any) => {
                        this.setState({emailAddress: e.target.value});
                    }}
                    handleSubmit={(event: any) => {
                        event.preventDefault();
                        this._sendMail();
                    }}
                    txtdescription={this.state.description}
                />
                <SuccessModal
                    show={this.state.condModalEmailSuccess}
                    onHide={() => {
                        this.setState({condModalEmailSuccess: !this.state.condModalEmailSuccess});
                    }}
                    txtdescription={"¡Información enviada correctamente!"}
                />
                <AlertModal
                    show={this.state.condAlertModal}
                    onHide={() => {
                        this.setState({condAlertModal: !this.state.condAlertModal});
                    }}
                    txtdescription={this.state.txtAlertModal}
                    txttitle={"Información"}
                />
            </Row>
        );
    }
}

export default EntrepreneurNeed;
