import React from "react";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";

const LoadingModal = (props: any) => {
    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop={'static'}
            className={'modal-loading'}
        >
            <Modal.Body>
                <Spinner animation="border" variant="primary"/>
            </Modal.Body>
        </Modal>
    );
};

export default LoadingModal;
