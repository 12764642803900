import React, {Component} from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Title from "../components/Title";
import InputGroup from "react-bootstrap/InputGroup";
import DescriptionModal from "../components/DescriptionModal";
import AlertModal from "../components/AlertModal";
import Table from "react-bootstrap/Table";
import {getCatalogDescendants} from "../actions/BudgetTool";
import Alert from "react-bootstrap/Alert";
import {getScheduledSavings} from "../actions/SavingCalculatorTool";
import {Redirect} from "react-router-dom";
import LoadingModal from "../components/LoadingModal";
import ErrorModal from "../components/ErrorModal";
import {sendMail} from "../actions/Global";
import ExportButton from "../components/ExportButton";
import EmailModal from "../components/EmailModal";
import SuccessModal from "../components/SuccessModal";
import Card from "react-bootstrap/cjs/Card";

class SavingCalculatorTool extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            toolTitle: "",
            //form
            type: "PROGRAMADO",
            monthlyCapital: "",
            rate: "",
            term: "",
            //result
            totalInterestAmount: "",
            totalSaving: "",
            totalCapital: "",
            savingTable: [],
            description: "",
            condModal: false,
            condAlertModal: false,
            txtAlertModal: false,
            loading: true,
            condError: false,
            redirect: null,
            emailAddress: "",
            condEmailModal: false,
            attachmentId: null,
            textError: "Servicio no disponible por el momento, intente más tarde...",
            condModalEmailSuccess: false,
            condRedirect: false,
        };
        this._calculateSaving = this._calculateSaving.bind(this);
    }

    async componentDidMount() {
        let condError = false;
        try {
            let toolData: any = await getCatalogDescendants(101);
            if (!toolData.message)
                this.setState({
                    description: toolData.jsonData.description,
                    toolTitle: toolData.name,
                    condModal: true,
                    attachmentId: toolData.jsonData.attachment
                });
            else
                condError = true;
        } catch (error) {
            condError = true;
        }
        this.setState({condError, loading: false, condRedirect: condError});
    }

    async _sendMail() {
        let tables: any = [];
        const {
            rate, term, monthlyCapital,
            totalCapital, type, savingTable, totalSaving, totalInterestAmount
        } = this.state;

        savingTable.forEach((value: any) => {
            tables.push({
                fee: value.fee,
                term: value.feeNumber,
                finalAmount: value.finishAmount,
                inicialAmount: value.initialAmount,
                interestAmount: value.interestAmount
            });
        });

        let dataForm = {
            "info": {
                rate,
                term,
                type,
                "amount": monthlyCapital,
            },
            "title": this.state.toolTitle,
            "result": {
                "amount": totalCapital,
                "totalSavings": totalSaving,
                "totalInterestAmount": totalInterestAmount
            },
            "tables": tables,
            "messages": [
                {
                    "text": `<p>En <strong>${term} meses</strong> su ahorro será de <strong>$${totalSaving}</strong> con una tasa de interés anual de <strong>${rate}%</strong></p>`,
                    "type": "primary"
                }
            ]
        };
        let body = {
            "to": this.state.emailAddress,
            "params": {
                "name": this.state.toolTitle,
                "description": this.state.description
            },
            "attachmentsParams": [
                {
                    "id": this.state.attachmentId,
                    "params": dataForm
                }
            ]
        };
        try {
            await this.setState({condEmailModal: false, loading: true});
            await sendMail(body);
            this.setState({loading: false, condModalEmailSuccess: true});
        } catch (e) {
            this.setState({
                textError: "Ocurrió un error al enviar la información, intente nuevamente.",
                condError: true,
                loading: false,
                condRedirect: false
            });
        }
    }

    async _calculateSaving() {
        const {type, monthlyCapital, rate, term} = this.state;
        if ((monthlyCapital.toString().trim() !== "" && rate.toString().trim() !== "" && term.toString().trim() !== "") &&
            (isFinite(monthlyCapital) && isFinite(rate) && isFinite(term))) {
            let savingData: any = await getScheduledSavings({
                type,
                monthlyCapital: parseFloat(monthlyCapital),
                rate: parseFloat(rate),
                term: parseFloat(term),
            });
            if (!savingData.message) {
                this.setState({
                    totalInterestAmount: savingData.totalInterestAmount,
                    totalSaving: savingData.totalSaving,
                    totalCapital: savingData.totalCapital,
                    savingTable: savingData.savingTable,
                });
            }
        } else {
            this.setState({
                condAlertModal: true,
                txtAlertModal: "Complete correctamente todos los campos para obtener resultados."
            });
        }

    }

    _cleanForm = () => {
        this.setState({
            //form
            monthlyCapital: "",
            rate: "",
            term: "",
            //result
            totalInterestAmount: "",
            totalSaving: "",
            totalCapital: "",
            savingTable: [],
            description: "",
        });
    };

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>;
        }
        return (
            <div className={"row"}>
                {this.state.toolTitle !== "" ? (
                    <div className={"col"}>
                        <Title txtTitle={this.state.toolTitle} handleClick={() => {
                            this.setState({redirect: "/"});
                        }}/>
                        <Card className={"mt-3 sb-card-content"}>
                            <Card.Body>
                                <div className={"row"}>
                                    <div className={"col"}>
                                        <Form>
                                            <Form.Row>
                                                <Form.Group as={Col} controlId="type" sm="auto" md="3">
                                                    <Form.Label>Tipo de Ahorro</Form.Label>
                                                    <Form.Control as="select" onChange={(e) => {
                                                        this.setState({type: e.target.value});
                                                    }}>
                                                        <option>PROGRAMADO</option>
                                                        <option>A LA VISTA</option>
                                                    </Form.Control>
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="monthlyCapital" sm="auto" md="3">
                                                    <Form.Label>Capital Mensual</Form.Label>
                                                    <InputGroup>
                                                        <InputGroup.Prepend>
                                                            <InputGroup.Text>$</InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <Form.Control type="number" placeholder="0.00"
                                                                      onChange={(e) => {
                                                                          this.setState({monthlyCapital: e.target.value});
                                                                      }} value={this.state.monthlyCapital}/>
                                                    </InputGroup>
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="term" sm="auto" md="3">
                                                    <Form.Label>Período en meses</Form.Label>
                                                    <Form.Control type="number" placeholder="Ej. 24" onChange={(e) => {
                                                        this.setState({term: e.target.value});
                                                    }} value={this.state.term}/>
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="rate" sm="auto" md="3">
                                                    <Form.Label>Tasa de interés anual</Form.Label>
                                                    <InputGroup>
                                                        <Form.Control type="number" placeholder="14.5"
                                                                      onChange={(e) => {
                                                                          this.setState({rate: e.target.value});
                                                                      }} value={this.state.rate}/>
                                                        <InputGroup.Append>
                                                            <InputGroup.Text>%</InputGroup.Text>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Form.Row>
                                            <div className={"row"}>
                                                <div className={"col"}>
                                                    <Button variant="success" className={"mt-2 mb-2 mr-2"} type="button"
                                                            onClick={this._calculateSaving}>Calcular Ahorro</Button>
                                                    <Button variant="danger" className={"mt-2 mb-2"} type="button"
                                                            onClick={this._cleanForm}>Limpiar</Button>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                                <div className={"row"}>
                                    <div className={"col"}>
                                        {this.state.totalSaving !== "" ?
                                            (<Row>
                                                <Form as={Col} xs={12} md={6}>
                                                    <Form.Group as={Row} controlId="totalCapital">
                                                        <Form.Label column xs={12} sm={6}>Capital Total</Form.Label>
                                                        <Col sm={6} xs={12}>
                                                            <InputGroup>
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text>$</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <Form.Control type="text" placeholder="0.00"
                                                                              value={this.state.totalCapital}
                                                                              readOnly/>
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="totalInterestAmount">
                                                        <Form.Label column xs={12} sm={6}>Interés ganado</Form.Label>
                                                        <Col xs={12} sm={6}>
                                                            <InputGroup>
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text>$</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <Form.Control type="text" placeholder="0.00"
                                                                              value={this.state.totalInterestAmount}
                                                                              readOnly/>
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="totalSaving">
                                                        <Form.Label column xs={12} sm={6}>Ahorro total</Form.Label>
                                                        <Col xs={12} sm={6}>
                                                            <InputGroup>
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text>$</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <Form.Control type="text" placeholder="0.00"
                                                                              value={this.state.totalSaving}
                                                                              readOnly/>
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>
                                                </Form>
                                                <Col xs={12} md={6}>
                                                    <Alert variant={"success"} className="col-12 sb-tool-oe-result">
                                                        <div className="d-flex">
                                                            <img className="sb-tool-oe-squirrel-icon" alt=""/>
                                                            <Row className={"m-0"}>
                                                                <p className="m-0 text-large">En <span
                                                                    className={"font-weight-bold"}>{this.state.term} meses </span>
                                                                    su ahorro será de <span
                                                                        className={"font-weight-bold"}>${this.state.totalSaving} </span>
                                                                    con una tasa de interés anual de <span
                                                                        className={"font-weight-bold"}>{this.state.rate}%</span>
                                                                </p>
                                                            </Row>
                                                        </div>
                                                    </Alert>
                                                </Col>
                                            </Row>) : <div/>}
                                        {this.state.type === "PROGRAMADO" && this.state.savingTable.length > 0 ? (
                                            <Table striped bordered hover size="sm" className={"tbl-amortization"}>
                                                <thead>
                                                <tr>
                                                    <th>Periodo</th>
                                                    <th>Saldo Inicial</th>
                                                    <th>Intereses</th>
                                                    <th>Cuota</th>
                                                    <th>Saldo Final</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.savingTable.map((feeData: any, index: number) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className={"text-center"}>{feeData.feeNumber}</td>
                                                            <td className={"text-right"}>{feeData.initialAmount}</td>
                                                            <td className={"text-right"}>{feeData.interestAmount}</td>
                                                            <td className={"text-right"}>{feeData.fee}</td>
                                                            <td className={"text-right"}>{feeData.finishAmount}</td>
                                                        </tr>
                                                    );
                                                })}
                                                </tbody>
                                            </Table>
                                        ) : <div/>}
                                    </div>
                                </div>
                                <Row className={"mt-4"}>
                                    <Col>
                                        <div className={"text-center"}>
                                            <ExportButton onClick={() => {
                                                if (this.state.totalSaving !== "")
                                                    this.setState({condEmailModal: true});
                                                else
                                                    this.setState({
                                                        condAlertModal: true,
                                                        txtAlertModal: "Ingrese su información para realizar la exportación."
                                                    });
                                            }}/>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </div>
                ) : <div/>}
                <DescriptionModal
                    show={this.state.condModal}
                    onHide={() => {
                        this.setState({condModal: !this.state.condModal});
                    }}
                    txtdescription={this.state.description}
                    heightModal={471}
                />
                <AlertModal
                    show={this.state.condAlertModal}
                    onHide={() => {
                        this.setState({condAlertModal: !this.state.condAlertModal});
                    }}
                    txtdescription={this.state.txtAlertModal}
                    txttitle={"Información"}
                />
                <LoadingModal
                    show={this.state.loading}
                    onHide={() => {
                        this.setState({loading: !this.state.loading});
                    }}
                />
                <ErrorModal
                    show={this.state.condError}
                    onHide={() => {
                        this.setState({
                            condError: !this.state.condError,
                            redirect: this.state.condRedirect ? "/" : null,
                        });
                    }}
                    txtdescription={this.state.textError}
                />
                <EmailModal
                    show={this.state.condEmailModal}
                    value={this.state.emailAddress}
                    onHide={() => {
                        this.setState({condEmailModal: false});
                    }}
                    onChange={(e: any) => {
                        this.setState({emailAddress: e.target.value});
                    }}
                    handleSubmit={(event: any) => {
                        event.preventDefault();
                        this._sendMail();
                    }}
                    txtdescription={this.state.description}
                />
                <SuccessModal
                    show={this.state.condModalEmailSuccess}
                    onHide={() => {
                        this.setState({condModalEmailSuccess: !this.state.condModalEmailSuccess});
                    }}
                    txtdescription={"¡Información enviada correctamente!"}
                />
                <AlertModal
                    show={this.state.condAlertModal}
                    onHide={() => {
                        this.setState({condAlertModal: !this.state.condAlertModal});
                    }}
                    txtdescription={this.state.txtAlertModal}
                    txttitle={"Información"}
                />
            </div>
        );
    }
}

export default SavingCalculatorTool;
