import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Image from "react-bootstrap/Image"

const redSquirrel = require("../assets/images/design/red-squirrel.png")

const DescriptionModal = (props: any) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Body className="pb-0 text-primary">
                <Row>
                    <Col xl={3} lg={3} md={4} className={"img-red-squirrel"}>
                        <Image src={redSquirrel} fluid={true}/>
                    </Col>
                    <Col xl={9} lg={9} md={8}>
                        <Modal.Title id="contained-modal-title-vcenter" className={'sb-model-title'}>
                            Descripción de la {props.type ? "Necesidad" : "Herramienta"}
                        </Modal.Title>
                        <hr/>
                        <p dangerouslySetInnerHTML={{__html: props.txtdescription}}>
                        </p>
                    </Col>
                </Row>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={props.onHide}>Continuar</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DescriptionModal;
