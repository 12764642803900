export const API_URL = "https://apiapliaplicativosfinancieros.superbancos.gob.ec/api/v1/";

export const Tools = [
    {
        title: "Creando mi presupuesto",
        routeName: "budget-tool",
        icon: "flaticon-resume fi-icon-home",
        color: 'ccn_color_icon1'
    },
    {
        title: "Midiendo mis gastos",
        routeName: "optimize-expenses-tool",
        icon: "las la-ruler-horizontal text-white",
        color: 'ccn_color_icon2'
    },
    {
        title: "Alcanzando mis metas",
        routeName: "financial-goal-tool",
        icon: "flaticon-trophy fi-icon-home",
        color: 'ccn_color_icon3'
    },
    {
        title: "¿Hasta dónde puedo endeudarme?",
        routeName: "debt-level-tool",
        icon: "ccn-flaticon-stopwatch-2 ccn-icon-home",
        color: 'ccn_color_icon4'
    },
    {
        title: "Uso inteligente de mi tarjeta de crédito",
        routeName: "credit-card-tool",
        icon: "fa fa-credit-card fa-white-icon",
        color: 'ccn_color_icon5'
    },
    {
        title: "En busca de mi vivienda propia",
        routeName: "mortgage-loan-tool",
        icon: "ccn-flaticon-house ccn-icon-home",
        color: 'ccn_color_icon6'
    },
    {
        title: "Mi plan de financiamiento",
        routeName: "credit-tool",
        icon: "ccn-flaticon-calendar-1 ccn-icon-home",
        color: 'ccn_color_icon7'
    },
    {
        title: "Inversión inteligente",
        routeName: "savings-policy-tool",
        icon: "las la-money-bill-wave text-white",
        color: 'ccn_color_icon8'
    },
    {
        title: "Calculadora del ahorro",
        routeName: "saving-calculator-tool",
        icon: "ccn-flaticon-calculator-1 ccn-icon-home",
        color: 'ccn_color_icon9'
    },
    {
        title: "Mi presupuesto paso a paso",
        routeName: "financial-budget-tool",
        icon: "ccn-flaticon-notebook-4 ccn-icon-home",
        color: 'ccn_color_icon10'
    }
];
export const DEBT_LEVEL_RESULTS = [
    {
        result: `¡CUIDADO!  
    Tus deudas estan superando tu capacidad de endeudamiento, es decir tienes un endeudamiento del {debtLevel}% 
    lo que pone en riesgo tu seguridad financiera. Aconsejamos reducir las deudas lo más pronto posible y
     no exceder el 40% de tus ingresos mensuales para curbir las obligaciones financieras.`,
        min: 31, max: 40, variant: "warning"
    },
    {
        result: `¡ALERTA! Los datos indican que aunque no tienes exceso de endeudamiento, 
    sin embargo estas cerca del límite. Se aconseja no exceder el 40% de tus ingresos 
    mensuales para curbir las obligaciones financieras.`,
        min: 41, max: 10000, variant: "danger"
    },
    {
        result: `¡Felicitaciones! Al parecer tienes las deudas bajo control,
     pero siempre es importante vigilar que no se exceda en las deudas.
      Se aconseja no exceder el 40% de tus ingresos mensuales para
       curbir las obligaciones financieras.`,
        min: 0, max: 30, variant: "success"
    },
];
export const CREDIT_CARD_RESULTS = {
    monthlyPayment: "Si pagas $ {monthlyPayment} dólares mensuales, terminarás de pagar tu deuda en {months} meses.",
    month: "Para terminar de cancelar el valor de tu tarjeta en {months} meses. Tus pagos mensuales deben ser de $ {monthlyPayment} dólares"
};
export const SAVINGS_POLICY_RESULT = `Si tienes una cuenta de ahorro a plazo fijo (póliza), a {term} días, 
podrás obtener un ahorro de $ {totalReceived} con una tasa de interés anual de {interestGained}%.`;

export const Needs = [
    {
        title: "¿Rentar o comprar casa?",
        routeName: "shopping-need",
        icon: "flaticon-refund fi-icon-home",
        color: 'ccn_color_icon1'
    },
    {
        title: "Mi jubilación deseada",
        routeName: "retirement-need",
        icon: "fa fa-line-chart fa-white-icon",
        color: 'ccn_color_icon2'
    },
    {
        title: "Mi negocio propio",
        routeName: "entrepreneur-need",
        icon: "las la-store-alt text-white",
        color: 'ccn_color_icon3'
    },
    {
        title: "Quiero ser independiente",
        routeName: "student-need",
        icon: "flaticon-medal fi-icon-home",
        color: 'ccn_color_icon4'
    },
    {
        title: "Finanzas en mi hogar",
        routeName: "household-finances-need",
        icon: "las la-people-carry text-white",
        color: 'ccn_color_icon5'
    },
];

export const FACEBOOK_URL = "https://www.facebook.com/SuperintendenciadeBancosEc"
export const TWITTER_URL = "https://twitter.com/superbancosEC"
export const INSTAGRAM_URL = "https://www.instagram.com/superbancosec"
export const YOUTUBE_URL = "https://www.youtube.com/user/superbancosec"
export const LINKEDIN_URL = "https://ec.linkedin.com/company/superintendencia-de-bancos-ec"
