import React from "react";
import Navbar from "react-bootstrap/Navbar";

const Header = () => {
    return (
        <Navbar>
            <Navbar.Brand href="https://educacionfinanciera.superbancos.gob.ec" className={"sb-header"}>
                <img className={"logo-navbar"} alt="logo"/>
            </Navbar.Brand>
        </Navbar>
    );
};

export default Header;
