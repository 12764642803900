import React from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";

const BudgetPanel = (props: any) => {
    let data = props.data
    return (
        <Row>
            <Col>
                <Table striped bordered hover size={"sm"} responsive={"xl"}>
                    <thead>
                    <tr className={"text-center"}>
                        <th>{data.name}</th>
                        <th>FRECUENCIA</th>
                        <th>MONTO ($)</th>
                        <th>MONTO MENSUAL ($)</th>
                        <th>MONTO ANUAL ($)</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data.children.map((rowData: any, rowIndex: number) => (
                        <tr key={rowIndex}>
                            <td>{rowData.newReg ? (
                                <Form.Control type="text" placeholder="Otro" value={rowData.name}
                                              onChange={(e) => {
                                                  props.handleInputChange(rowIndex, e, 'name');
                                              }}/>
                            ) : rowData.name}</td>
                            <td>
                                <Form.Control as="select" onChange={(e) => {
                                    props.handleInputChange(rowIndex, e, 'frequency');
                                }}>
                                    {props.frequenciesData.map((frequency: any, frequencyIndex: number) => (
                                        <option key={frequencyIndex}>{frequency.name}</option>
                                    ))}
                                </Form.Control>
                            </td>
                            <td className={'td-txt-rigth'}>
                                <Form.Control type="number" placeholder="0.00" value={rowData.amount} onChange={(e) => {
                                    props.handleInputChange(rowIndex, e, 'amount');
                                }}/>
                            </td>
                            <td className={'td-txt-rigth'}>
                                <Form.Control type="number" placeholder="0.00" readOnly
                                              value={!isNaN(rowData.monthlyAmount) ? parseFloat(rowData.monthlyAmount).toFixed(2) : '0.00'}/>
                            </td>
                            <td className={'td-txt-rigth'}>
                                <Form.Control type="number" placeholder="0.00" readOnly
                                              value={!isNaN(rowData.yearlyAmount) ? parseFloat(rowData.yearlyAmount).toFixed(2) : '0.00'}/>
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td colSpan={5}>
                            <Button variant="primary" className={"mb-2"} type="button"
                                    onClick={props.addRow}>+ Agregar Nuevo</Button>
                        </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr className={'font-weight-bold text-right'}>
                        <td colSpan={3} className={'text-center'}>TOTAL</td>
                        <td>{!isNaN(data.totalMonthlyAmount) ? data.totalMonthlyAmount.toFixed(2) : '0.00'}</td>
                        <td>{!isNaN(data.totalYearlyAmount) ? data.totalYearlyAmount.toFixed(2) : '0.00'}</td>
                    </tr>
                    </tfoot>
                </Table>
            </Col>
        </Row>
    );
};

export default BudgetPanel;
