import React, {Component} from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Title from "../components/Title";
import InputGroup from "react-bootstrap/InputGroup";
import DescriptionModal from "../components/DescriptionModal";
import AlertModal from "../components/AlertModal";
import Table from "react-bootstrap/Table";
import {getCatalogDescendants} from "../actions/BudgetTool";
import {getCreditAmortization} from "../actions/CreditTool";
import {Redirect} from "react-router-dom";
import LoadingModal from "../components/LoadingModal";
import ErrorModal from "../components/ErrorModal";
import {sendMail} from "../actions/Global";
import EmailModal from "../components/EmailModal";
import SuccessModal from "../components/SuccessModal";
import ExportButton from "../components/ExportButton";
import Card from "react-bootstrap/cjs/Card";


class CreditTool extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            toolTitle: "",
            creditValue: "",
            annualInterestRate: "",
            installmentsNumber: "",
            amortization: "ALEMANA",
            periodRate: 0,
            fee: 0, //FRANCESA O ALEMANA
            totalInterestAmount: 0,
            totalPayment: 0,
            amortizationTable: [],
            description: "",
            condModal: false,
            condSaving: false,
            condAlertModal: false,
            txtAlertModal: false,
            txtAmortizationEvolution: "alemana",
            lblFeeResult: "",
            loading: true,
            condError: false,
            redirect: null,
            emailAddress: "",
            condEmailModal: false,
            attachmentId: null,
            textError: "Servicio no disponible por el momento, intente más tarde...",
            condModalEmailSuccess: false,
            condRedirect: false,
        };
        this._calculateCredit = this._calculateCredit.bind(this);
    }

    async componentDidMount() {
        let condError = false;
        try {
            let toolData: any = await getCatalogDescendants(97);
            if (!toolData.message)
                this.setState({
                    description: toolData.jsonData.description,
                    toolTitle: toolData.name,
                    condModal: true,
                    attachmentId: toolData.jsonData.attachment
                });
            else
                condError = true;
        } catch (error) {
            condError = true;
        }
        this.setState({condError, loading: false, condRedirect: condError});
    }

    async _calculateCredit() {
        const {creditValue, annualInterestRate, installmentsNumber, amortization} = this.state;
        if ((creditValue.toString().trim() !== "" && annualInterestRate.toString().trim() !== "" && installmentsNumber.toString().trim() !== "") &&
            (isFinite(creditValue) && isFinite(annualInterestRate) && isFinite(installmentsNumber))) {
            let amortizationData: any = await getCreditAmortization({
                amount: creditValue,
                rate: annualInterestRate,
                term: installmentsNumber,
                type: amortization
            });
            if (!amortizationData.message) {
                this.setState({
                    amortizationTable: amortizationData.amortizationTable,
                    txtAmortizationEvolution: amortization.toLowerCase(),
                    totalInterestAmount: amortizationData.totalInterestAmount,
                    totalPayment: amortizationData.totalPayment,
                    periodRate: amortizationData.periodRate,
                    fee: amortization === "ALEMANA" ? amortizationData.amortization : amortizationData.fee,
                    lblFeeResult: amortization === "ALEMANA" ? "Pago a efectuar" : "Cuota de préstamo"
                });
            }
        } else {
            this.setState({
                condAlertModal: true,
                txtAlertModal: "Complete correctamente todos los campos para obtener resultados."
            });
        }
    }

    _cleanForm = () => {
        this.setState({
            creditValue: "",
            annualInterestRate: "",
            installmentsNumber: "",
            amortization: "ALEMANA",
            periodRate: 0,
            fee: 0, //FRANCESA O ALEMANA
            totalInterestAmount: 0,
            totalPayment: 0,
            amortizationTable: [],
        });
    };

    async _sendMail() {
        const {
            annualInterestRate, installmentsNumber,
            creditValue, amortization, amortizationTable, fee, totalInterestAmount,
            totalPayment, periodRate
        } = this.state;

        let dataForm = {
            "info": {
                "rate": annualInterestRate,
                "term": installmentsNumber,
                "type": amortization,
                "amount": creditValue,
            },
            "title": this.state.toolTitle,
            "result": {
                fee,
                totalPayment,
                totalInterestAmount,
                periodRate,
                amount: creditValue
            },
            "tables": amortizationTable
        };
        let body = {
            "to": this.state.emailAddress,
            "params": {
                "name": this.state.toolTitle,
                "description": this.state.description
            },
            "attachmentsParams": [
                {
                    "id": this.state.attachmentId,
                    "params": dataForm
                }
            ]
        };
        try {
            await this.setState({condEmailModal: false, loading: true});
            await sendMail(body);
            this.setState({loading: false, condModalEmailSuccess: true});
        } catch (e) {
            this.setState({
                textError: "Ocurrió un error al enviar la información, intente nuevamente.",
                condError: true,
                loading: false,
                condRedirect: false
            });
        }
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>;
        }
        return (
            <div className={"row"}>
                {this.state.toolTitle !== "" ? (
                    <div className={"col"}>
                        <Title txtTitle={this.state.toolTitle} handleClick={() => {
                            this.setState({redirect: "/"});
                        }}/>
                        <Card className={"mt-3 sb-card-content"}>
                            <Card.Body>
                                <div className={"row"}>
                                    <div className={"col"}>
                                        <Form>
                                            <Form.Row>
                                                <Form.Group as={Col} controlId="creditValue" sm="auto" md="3">
                                                    <Form.Label>Valor del crédito</Form.Label>
                                                    <InputGroup>
                                                        <InputGroup.Prepend>
                                                            <InputGroup.Text>$</InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <Form.Control type="number" placeholder="0.00"
                                                                      onChange={(e) => {
                                                                          this.setState({creditValue: e.target.value});
                                                                      }} value={this.state.creditValue}/>
                                                    </InputGroup>
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="annualInterestRate" sm="auto" md="3">
                                                    <Form.Label>Tasa de interés anual</Form.Label>
                                                    <InputGroup>
                                                        <InputGroup.Prepend>
                                                            <InputGroup.Text>%</InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <Form.Control type="number" placeholder="14.5"
                                                                      onChange={(e) => {
                                                                          this.setState({annualInterestRate: e.target.value});
                                                                      }} value={this.state.annualInterestRate}/>
                                                    </InputGroup>
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="installmentsNumber" sm="auto" md="3">
                                                    <Form.Label>Nº cuotas (meses)</Form.Label>
                                                    <Form.Control type="number" placeholder="Ej. 24" onChange={(e) => {
                                                        this.setState({installmentsNumber: e.target.value});
                                                    }} value={this.state.installmentsNumber}/>
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="amortization" sm="auto" md="3">
                                                    <Form.Label>Amortización</Form.Label>
                                                    <Form.Control as="select" onChange={(e) => {
                                                        this.setState({amortization: e.target.value});
                                                    }}>
                                                        <option>ALEMANA</option>
                                                        <option>FRANCESA</option>
                                                    </Form.Control>
                                                </Form.Group>
                                            </Form.Row>
                                            <div className={"row"}>
                                                <div className={"col"}>
                                                    <Button variant="success" className={"mt-2 mb-2 mr-2"} type="button"
                                                            onClick={this._calculateCredit}>Calcular Cuota</Button>
                                                    <Button variant="danger" className={"mt-2 mb-2"} type="button"
                                                            onClick={this._cleanForm}>Limpiar</Button>
                                                </div>
                                            </div>

                                        </Form>
                                    </div>
                                </div>
                                <div className={"row"}>
                                    <div className={"col"}>
                                        {this.state.amortizationTable.length > 0 ?
                                            (<div>
                                                <Form>
                                                    <Form.Group as={Row} controlId="periodRate">
                                                        <Form.Label column xs={6} sm={6} md={4} lg={3} xl={3}>
                                                            Tasa de Interés Mensual
                                                        </Form.Label>
                                                        <Col lg={3} md={4} sm={6} xs={12}>
                                                            <InputGroup>
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text>%</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <Form.Control type="text" placeholder="0.00"
                                                                              value={this.state.periodRate}/>
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="periodRate">
                                                        <Form.Label column xs={6} sm={6} md={4} lg={3} xl={3}>
                                                            {this.state.lblFeeResult}
                                                        </Form.Label>
                                                        <Col lg={3} md={4} sm={6} xs={12}>
                                                            <InputGroup>
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text>$</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <Form.Control type="text" placeholder="0.00"
                                                                              value={this.state.fee}/>
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>
                                                </Form>
                                                <h4 className={"d-flex justify-content-center"}>Evolución de la deuda
                                                    (amortización {this.state.txtAmortizationEvolution})</h4>
                                                <Table striped bordered hover size="sm" className={"tbl-amortization"}>
                                                    <thead>
                                                    <tr>
                                                        <th>Meses</th>
                                                        <th>Deuda Total</th>
                                                        <th>Amortización</th>
                                                        <th>Intereses</th>
                                                        <th>Cuota Mensual</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {this.state.amortizationTable.map((feeData: any, index: number) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td className={"text-center"}>{feeData.feeNumber}</td>
                                                                <td className={"text-right"}>{feeData.amount}</td>
                                                                <td className={"text-right"}>{feeData.amortization}</td>
                                                                <td className={"text-right"}>{feeData.interestAmount}</td>
                                                                <td className={"text-right"}>{feeData.fee}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                    </tbody>
                                                </Table>
                                                <Form>
                                                    <Form.Group as={Row} controlId="creditValue">
                                                        <Form.Label column xs={6} sm={6} md={4} lg={3} xl={3}>
                                                            VALOR DE DEUDA/COMPRA
                                                        </Form.Label>
                                                        <Col lg={3} md={4} sm={6} xs={12}>
                                                            <InputGroup>
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text>$</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <Form.Control type="text" placeholder="0.00"
                                                                              value={this.state.creditValue}/>
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="totalInterestAmount">
                                                        <Form.Label column xs={6} sm={6} md={4} lg={3} xl={3}>
                                                            INTERÉS PAGADO
                                                        </Form.Label>
                                                        <Col lg={3} md={4} sm={6} xs={12}>
                                                            <InputGroup>
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text>$</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <Form.Control type="text" placeholder="0.00"
                                                                              value={this.state.totalInterestAmount}/>
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="totalPayment">
                                                        <Form.Label column xs={6} sm={6} md={4} lg={3} xl={3}>
                                                            TOTAL CANCELADO
                                                        </Form.Label>
                                                        <Col lg={3} md={4} sm={6} xs={12}>
                                                            <InputGroup>
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text>$</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <Form.Control type="text" placeholder="0.00"
                                                                              value={this.state.totalPayment}/>
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>
                                                </Form>
                                            </div>) : <div/>
                                        }
                                    </div>
                                </div>
                                <Row className={"mt-4"}>
                                    <Col>
                                        <div className={"text-center"}>
                                            <ExportButton onClick={() => {
                                                if (this.state.amortizationTable.length > 0)
                                                    this.setState({condEmailModal: true});
                                                else
                                                    this.setState({
                                                        condAlertModal: true,
                                                        txtAlertModal: "Ingrese su información para realizar la exportación."
                                                    });
                                            }}/>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body></Card>
                    </div>
                ) : <div/>}
                <DescriptionModal
                    show={this.state.condModal}
                    onHide={() => {
                        this.setState({condModal: !this.state.condModal});
                    }}
                    txtdescription={this.state.description}
                />
                <AlertModal
                    show={this.state.condAlertModal}
                    onHide={() => {
                        this.setState({condAlertModal: !this.state.condAlertModal});
                    }}
                    txtdescription={this.state.txtAlertModal}
                    txttitle={"Información"}
                />
                <LoadingModal
                    show={this.state.loading}
                    onHide={() => {
                        this.setState({loading: !this.state.loading});
                    }}
                />
                <ErrorModal
                    show={this.state.condError}
                    onHide={() => {
                        this.setState({
                            condError: !this.state.condError,
                            redirect: this.state.condRedirect ? "/" : null,
                        });
                    }}
                    txtdescription={this.state.textError}
                />
                <EmailModal
                    show={this.state.condEmailModal}
                    value={this.state.emailAddress}
                    onHide={() => {
                        this.setState({condEmailModal: false});
                    }}
                    onChange={(e: any) => {
                        this.setState({emailAddress: e.target.value});
                    }}
                    handleSubmit={(event: any) => {
                        event.preventDefault();
                        this._sendMail();
                    }}
                    txtdescription={this.state.description}
                />
                <SuccessModal
                    show={this.state.condModalEmailSuccess}
                    onHide={() => {
                        this.setState({condModalEmailSuccess: !this.state.condModalEmailSuccess});
                    }}
                    txtdescription={"¡Información enviada correctamente!"}
                />
                <AlertModal
                    show={this.state.condAlertModal}
                    onHide={() => {
                        this.setState({condAlertModal: !this.state.condAlertModal});
                    }}
                    txtdescription={this.state.txtAlertModal}
                    txttitle={"Información"}
                />
            </div>
        );
    }
}

export default CreditTool;
