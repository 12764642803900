import React, {Component} from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Title from "../components/Title";
import InputGroup from "react-bootstrap/InputGroup";
import {getCatalogDescendants} from "../actions/BudgetTool";
import DescriptionModal from "../components/DescriptionModal";
import {buyOrRentHouse} from "../actions/ShoppingNeed";
import {Redirect} from "react-router-dom";
import LoadingModal from "../components/LoadingModal";
import ErrorModal from "../components/ErrorModal";
import AlertModal from "../components/AlertModal";
import Alert from "react-bootstrap/cjs/Alert";
import {sendMail} from "../actions/Global";
import ExportButton from "../components/ExportButton";
import EmailModal from "../components/EmailModal";
import SuccessModal from "../components/SuccessModal";
import Card from "react-bootstrap/cjs/Card";

class ShoppingNeed extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            toolTitle: "",
            description: "",
            shoppingData: [],
            condModal: false,
            loading: true,
            condError: false,
            redirect: null,
            condAlertModal: false,
            txtAlertModal: false,
            result: [],
            emailAddress: "",
            condEmailModal: false,
            attachmentId: null,
            textError: "Servicio no disponible por el momento, intente más tarde...",
            condModalEmailSuccess: false,
            condRedirect: false,
        };
        this._calculate = this._calculate.bind(this);
    }

    async componentDidMount() {
        let condError = false;
        try {
            let shoppingData: any = await getCatalogDescendants(104);
            if (!shoppingData.message)
                this.setState({
                    description: shoppingData.jsonData.description,
                    toolTitle: shoppingData.name,
                    condModal: true,
                    shoppingData: shoppingData.children,
                    attachmentId: shoppingData.jsonData.attachment
                });
            else
                condError = true;
        } catch (error) {
            condError = true;
        }
        this.setState({condError, loading: false, condRedirect: condError});
    }

    _handleShoppingChange = (type: string, name: string, e: any) => {
        let shoppingDataAux = this.state.shoppingData;
        shoppingDataAux.forEach((shoppingType: any, index: any) => {
            if (shoppingType.jsonData.name === type) {
                shoppingType.children.forEach((shoppingChild: any, indexChild: any) => {
                    if (shoppingChild.jsonData.name === name) {
                        shoppingDataAux[index].children[indexChild].value = e.target.value;
                    }
                });
            }
        });
        this.setState({shoppingData: shoppingDataAux});
    };

    async _calculate() {
        let bodyObj: any = {
            "buy": {
                "houseCost": 0,
                "creditAmount": 0,
                "creditTermYears": 0,
                "rate": 0,
                "legalCost": 0,
                "yearlyTaxes": 0,
                "yearlyMaintenance": 0,
                "monthlyBasicServices": 0,
                "monthlyOtherExpenses": 0
            },
            "rent": {
                "monthlyCost": 0,
                "yearlyIncrease": 0,
                "monthlyBasicServices": 0,
                "monthlyOtherExpenses": 0
            },
            "otherData": {
                "yearsToBuy": 0,
                "yearlyInflation": 0
            }
        };
        let condApi = true;
        let shoppingDataAux = this.state.shoppingData;
        shoppingDataAux.forEach((shoppingType: any) => {
            shoppingType.children.forEach((shoppingChild: any) => {
                if (shoppingChild.value && shoppingChild.value.trim() !== "" && isFinite(shoppingChild.value))
                    bodyObj[shoppingType.jsonData.name][shoppingChild.jsonData.name] = parseFloat(shoppingChild.value);
                else
                    condApi = false;
            });
        });
        if (condApi && (
            isFinite(bodyObj.buy.creditAmount) && isFinite(bodyObj.buy.creditTermYears) && isFinite(bodyObj.buy.rate) && isFinite(bodyObj.buy.legalCost) &&
            parseFloat(bodyObj.buy.creditAmount) > 0 && parseFloat(bodyObj.buy.creditTermYears) > 0 && parseFloat(bodyObj.buy.rate) > 0
        )) {
            const resultApi = await buyOrRentHouse(bodyObj);
            console.log(resultApi);
            this.setState({...this.state, result: resultApi});
        } else {
            this.setState({
                condAlertModal: true,
                txtAlertModal: "Complete correctamente todos los campos para obtener resultados."
            });
        }
    }

    _cleanForm = () => {
        let shoppingDataAux = this.state.shoppingData;
        shoppingDataAux.forEach((shoppingType: any, index: any) => {
            shoppingType.children.forEach((shoppingChild: any, indexChild: any) => {
                shoppingDataAux[index].children[indexChild].value = "";
            });
        });
        this.setState({...this.state, shoppingData: shoppingDataAux, result: []});
    };

    async _sendMail() {
        let rentPrices: any = [];
        let shopPrices: any = [];
        let otherData: any = [];
        const {shoppingData, result} = this.state;
        //shopPrices
        shoppingData[0].children.forEach((prices: any) => {
            shopPrices.push({
                label: prices.name,
                value: isFinite(prices.value) ? parseFloat(prices.value).toFixed(2) : "0.00"
            });
        });
        //rentPrices
        shoppingData[1].children.forEach((prices: any) => {
            rentPrices.push({
                label: prices.name,
                value: isFinite(prices.value) ? parseFloat(prices.value).toFixed(2) : "0.00"
            });
        });
        //otherData
        shoppingData[2].children.forEach((prices: any) => {
            otherData.push({
                label: prices.name,
                value: isFinite(prices.value) ? parseFloat(prices.value).toFixed(2) : "0.00"
            });
        });

        let dataForm = {
            "title": this.state.toolTitle,
            "tables": result,
            rentPrices,
            shopPrices,
            otherData
        };
        let body = {
            "to": this.state.emailAddress,
            "params": {
                "name": this.state.toolTitle,
                "description": this.state.description
            },
            "attachmentsParams": [
                {
                    "id": this.state.attachmentId,
                    "params": dataForm
                }
            ]
        };
        try {
            await this.setState({condEmailModal: false, loading: true});
            await sendMail(body);
            this.setState({loading: false, condModalEmailSuccess: true});
        } catch (e) {
            this.setState({
                textError: "Ocurrió un error al enviar la información, intente nuevamente.",
                condError: true,
                loading: false,
                condRedirect: false
            });
        }
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>;
        }
        return (
            <Row>
                {this.state.toolTitle !== "" ? (
                    <Col>
                        <Title txtTitle={this.state.toolTitle} handleClick={() => {
                            this.setState({redirect: "/"});
                        }}/>
                        <Card className={"mt-3 sb-card-content"}>
                            <Card.Body>
                                <Form as={Row}>
                                    {this.state.shoppingData.map((shoppingType: any, index: number) => {
                                        return (
                                            <Col md={6} key={index}>
                                                <hr/>
                                                <h6 className={"font-weight-bold"}>{shoppingType.name}</h6>
                                                {shoppingType.children.map((shoppingChild: any, indexChild: number) => (
                                                        <Form.Group key={indexChild} as={Row}
                                                                    controlId={"shopping" + shoppingChild.id}>
                                                            <Form.Label column sm={8} md={7}>
                                                                {shoppingChild.name}
                                                            </Form.Label>
                                                            <Col sm={4} md={5}>
                                                                <InputGroup>
                                                                    {shoppingChild.id !== 126 ? (
                                                                        <InputGroup.Prepend>
                                                                            <InputGroup.Text>{shoppingChild.id === 125 ? "#" : "$"}</InputGroup.Text>
                                                                        </InputGroup.Prepend>
                                                                    ) : (<div/>)}
                                                                    <Form.Control type="number" placeholder="0.00"
                                                                                  className={"text-right"}
                                                                                  onChange={(e) => {
                                                                                      this._handleShoppingChange(shoppingType.jsonData.name, shoppingChild.jsonData.name, e);
                                                                                  }}
                                                                                  value={shoppingChild.value}
                                                                    />
                                                                    {shoppingChild.id === 126 ? (
                                                                        <InputGroup.Append>
                                                                            <InputGroup.Text>%</InputGroup.Text>
                                                                        </InputGroup.Append>
                                                                    ) : (<div/>)}
                                                                </InputGroup>
                                                            </Col>
                                                        </Form.Group>
                                                    )
                                                )}
                                            </Col>
                                        );
                                    })}
                                </Form>
                                <Row>
                                    <Col>
                                        <Button variant="success" className={"mt-2 mb-2 mr-2"} type="button"
                                                onClick={this._calculate}>Calcular Costos</Button>
                                        <Button variant="danger" className={"mt-2 mb-2"} type="button"
                                                onClick={this._cleanForm}>Limpiar</Button>
                                    </Col>
                                </Row>
                                {this.state.result && this.state.result.length > 0 ? (
                                    <Row>
                                        <Col sm={12} md={12}>
                                            <h5 className={"font-weight-bold text-center"}>RESULTADOS</h5>
                                        </Col>
                                        <Col>
                                            {this.state.result.map((value: any, index: number) => (
                                                <Row key={index}>
                                                    <Col xs={12}>
                                                        <hr/>
                                                    </Col>
                                                    <Col xs={12}>
                                                        <Row>
                                                            <h5 className={"col text-center"}>
                                                                <strong>{value.title.toUpperCase()}</strong></h5>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={12} sm={12} md={7} lg={6} xl={6}>
                                                                {index > 0 ? (
                                                                    <Form.Group as={Row}>
                                                                        <Form.Label column sm={8} md={7}>
                                                                            Costo de alquiler
                                                                        </Form.Label>
                                                                        <Col sm={4} md={5}>
                                                                            <InputGroup>
                                                                                <InputGroup.Prepend>
                                                                                    <InputGroup.Text>$</InputGroup.Text>
                                                                                </InputGroup.Prepend>
                                                                                <Form.Control type="text"
                                                                                              placeholder="0.00"
                                                                                              className={"text-right"}
                                                                                              value={value.rentCost}
                                                                                              readOnly
                                                                                />
                                                                            </InputGroup>
                                                                        </Col>
                                                                    </Form.Group>) : (<Row/>)}
                                                                <Form.Group as={Row}>
                                                                    <Form.Label column sm={8} md={7}>
                                                                        Costo de compra
                                                                    </Form.Label>
                                                                    <Col sm={4} md={5}>
                                                                        <InputGroup>
                                                                            <InputGroup.Prepend>
                                                                                <InputGroup.Text>$</InputGroup.Text>
                                                                            </InputGroup.Prepend>
                                                                            <Form.Control type="text" placeholder="0.00"
                                                                                          className={"text-right"}
                                                                                          value={value.buyCost}
                                                                                          readOnly
                                                                            />
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group>
                                                                {index > 0 ? (
                                                                    <Form.Group as={Row}>
                                                                        <Form.Label column sm={8} md={7}
                                                                                    className={"text-bold"}>
                                                                            Ahorro
                                                                        </Form.Label>
                                                                        <Col sm={4} md={5}>
                                                                            <InputGroup>
                                                                                <InputGroup.Prepend>
                                                                                    <InputGroup.Text
                                                                                        className={"text-bold"}>$</InputGroup.Text>
                                                                                </InputGroup.Prepend>
                                                                                <Form.Control type="text"
                                                                                              placeholder="0.00"
                                                                                              className={"text-right text-bold"}
                                                                                              value={value.save}
                                                                                              readOnly
                                                                                />
                                                                            </InputGroup>
                                                                        </Col>
                                                                    </Form.Group>) : (<Row/>)}
                                                            </Col>
                                                            <Col xs={12} sm={12} md={5} lg={6} xl={6}>
                                                                {index > 0 ? (
                                                                    <Alert className="col-12 back-blue">
                                                                        {value.message}
                                                                    </Alert>) : (<Row/>)}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            ))}
                                        </Col>
                                    </Row>
                                ) : <div/>}
                                <Row className={"mt-4"}>
                                    <Col>
                                        <div className={"text-center"}>
                                            <ExportButton onClick={() => {
                                                if (this.state.result && this.state.result.length > 0)
                                                    this.setState({condEmailModal: true});
                                                else
                                                    this.setState({
                                                        condAlertModal: true,
                                                        txtAlertModal: "Calcule los costos para realizar la exportación."
                                                    });
                                            }}/>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                ) : <div/>}
                <DescriptionModal
                    show={this.state.condModal}
                    onHide={() => {
                        this.setState({condModal: !this.state.condModal});
                    }}
                    txtdescription={this.state.description}
                    type={"true"}
                />
                <LoadingModal
                    show={this.state.loading}
                    onHide={() => {
                        this.setState({loading: !this.state.loading});
                    }}
                />
                <ErrorModal
                    show={this.state.condError}
                    onHide={() => {
                        this.setState({
                            condError: !this.state.condError,
                            redirect: this.state.condRedirect ? "/" : null,
                        });
                    }}
                    txtdescription={this.state.textError}
                />
                <EmailModal
                    show={this.state.condEmailModal}
                    value={this.state.emailAddress}
                    onHide={() => {
                        this.setState({condEmailModal: false});
                    }}
                    onChange={(e: any) => {
                        this.setState({emailAddress: e.target.value});
                    }}
                    handleSubmit={(event: any) => {
                        event.preventDefault();
                        this._sendMail();
                    }}
                    txtdescription={this.state.description}
                />
                <SuccessModal
                    show={this.state.condModalEmailSuccess}
                    onHide={() => {
                        this.setState({condModalEmailSuccess: !this.state.condModalEmailSuccess});
                    }}
                    txtdescription={"¡Información enviada correctamente!"}
                />
                <AlertModal
                    show={this.state.condAlertModal}
                    onHide={() => {
                        this.setState({condAlertModal: !this.state.condAlertModal});
                    }}
                    txtdescription={this.state.txtAlertModal}
                    txttitle={"Información"}
                />
            </Row>
        );
    }
}

export default ShoppingNeed;
