import React, {Component} from "react";
import Card from "react-bootstrap/cjs/Card";
import Row from "react-bootstrap/cjs/Row";
import Col from "react-bootstrap/cjs/Col";
import DescriptionModal from "../components/DescriptionModal";
import Alert from "react-bootstrap/cjs/Alert";
import Form from "react-bootstrap/cjs/Form";
import {Redirect} from "react-router-dom";
import {getCatalogDescendants} from "../actions/BudgetTool";
import LoadingModal from "../components/LoadingModal";
import ErrorModal from "../components/ErrorModal";
import Title from "../components/Title";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import {sendMail} from "../actions/Global";
import EmailModal from "../components/EmailModal";
import SuccessModal from "../components/SuccessModal";
import AlertModal from "../components/AlertModal";
import ExportButton from "../components/ExportButton";

class OptimizeExpensesTool extends Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            toolTitle: "",
            description: "",
            questions: "",
            condModal: false,
            loading: true,
            condError: false,
            redirect: null,
            inputs: [],
            countNewRow: 0,
            total: {title: "TOTAL DE REDUCCIÓN DE GASTOS", current: 0, goal: 0},
            percent: 0,
            // eslint-disable-next-line
            result: "Al establecer metas de ahorro y optimizar algunos gastos cotidianos, podrás ahorrar el {percent}% de tus gastos, esto significa una reducción de ${reductionValue}.",
            emailAddress: '',
            condEmailModal: false,
            attachmentId: null,
            textError: "Servicio no disponible por el momento, intente más tarde...",
            condModalEmailSuccess: false,
            txtAlertModal: '',
            condRedirect: false,
            condAlertModal: false
        };
    }

    async componentDidMount() {
        let condError = false;
        try {
            let budgetData: any = await getCatalogDescendants(19)
            if (!budgetData.message)
                this.setState({
                    description: budgetData.jsonData.description,
                    questions: budgetData.jsonData.questions,
                    toolTitle: budgetData.name,
                    condModal: true,
                    inputs: budgetData.children,
                    attachmentId: budgetData.jsonData.attachment
                })
            else
                condError = true
        } catch (error) {
            condError = true
        }
        this.setState({condError, loading: false, condRedirect: condError})
    }

    async _sendMail() {
        let tables: any = [];
        const {inputs, result, total, percent} = this.state;

        inputs.forEach((input: any) => {
            tables.push({
                goal: isFinite(input.goal) ? parseFloat(input.goal).toFixed(2) : "0.00",
                label: input.name,
                current: isFinite(input.current) ? parseFloat(input.current).toFixed(2) : "0.00"
            })
        })
        let resultTemp = result.replace("{percent}", isFinite(percent) ? percent : 0);
        resultTemp = resultTemp.replace("{reductionValue}", (isFinite(total.current) && isFinite(total.goal) && parseFloat(total.current) > parseFloat(total.goal)) ?
            ((total.current - total.goal).toFixed(2)) : "0.00");

        let dataForm = {
            "title": this.state.toolTitle,
            "messages": [
                {
                    "text": resultTemp,
                    "type": "success"
                },
                {
                    "text": this.state.questions.join(" "),
                    "type": "primary"
                }
            ],
            "table": tables
        };
        let body = {
            "to": this.state.emailAddress,
            "params": {
                "name": this.state.toolTitle,
                "description": this.state.description
            },
            "attachmentsParams": [
                {
                    "id": this.state.attachmentId,
                    "params": dataForm
                }
            ]
        };
        try {
            await this.setState({condEmailModal: false, loading: true})
            await sendMail(body)
            this.setState({loading: false, condModalEmailSuccess: true})
        } catch (e) {
            this.setState({
                textError: "Ocurrió un error al enviar la información, intente nuevamente.",
                condError: true,
                loading: false,
                condRedirect: false
            })
        }
    }

    _renderResult() {
        const {percent, result, total} = this.state;
        let resultTemp = result.replace("{percent}", isFinite(percent) ? percent : 0);
        resultTemp = resultTemp.replace("{reductionValue}", (isFinite(total.current) && isFinite(total.goal) && parseFloat(total.current) > parseFloat(total.goal)) ?
            ((total.current - total.goal).toFixed(2)) : "0.00");
        return resultTemp;
    }

    _deleteRow = (indexRow: number) => {
        let {inputs, total} = this.state;
        inputs.splice(indexRow, 1);
        total.current = 0;
        total.goal = 0;
        inputs.forEach((row: any) => {
            total.current += isFinite(row.current) ? parseFloat(row.current) : 0;
            total.goal += isFinite(row.goal) ? parseFloat(row.goal) : 0;
        });
        const percent = ((total.goal / total.current) * 100).toFixed(2);
        this.setState({inputs, total, percent});
    };

    _handleChangeName = (index: number, e: any) => {
        let {inputs} = this.state;
        inputs[index]["name"] = e.target.value
        this.setState({inputs});
    };

    render() {
        const {questions, inputs, total} = this.state;
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>
        }
        return <Row>
            {this.state.toolTitle !== "" ? (
                <Col>
                    <Title txtTitle={this.state.toolTitle} handleClick={() => {
                        this.setState({redirect: "/"})
                    }}/>
                    <Card className="mt-3 sb-card-content">
                        <Card.Body>
                            <Row>
                                <Col lg={7} md={12} sm={12}>
                                    <Row>
                                        <Col md={5} sm={12} xs={12}/>
                                        <Col md={7} sm={12} xs={12}>
                                            <Row>
                                                <p className="col text-overflow text-bold">ACTUAL</p>
                                                <p className="col text-overflow text-bold">META</p>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Form>
                                        {inputs ? inputs.map((input: any, inputKey: number) =>
                                            (<Form.Group key={input.id} as={Row}>
                                                {input.type === 1 ?
                                                    <InputGroup as={Col} md={5} sm={12} xs={12}>
                                                        <Form.Control type="text"
                                                                      placeholder="Otro Gasto"
                                                                      onChange={(e) => {
                                                                          this._handleChangeName(inputKey, e);
                                                                      }} value={input.name}/>
                                                        <InputGroup.Append>
                                                            <Button variant="danger"
                                                                    type="button"
                                                                    onClick={() => {
                                                                        this._deleteRow(inputKey);
                                                                    }}><i
                                                                className={"fa fa-minus"}/></Button>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                    :
                                                    <Form.Label column md={5} sm={12} xs={12}>{input.name}</Form.Label>}
                                                <Col md={7} sm={12} xs={12}>
                                                    <Row>
                                                        <InputGroup className={"col"}>
                                                            <InputGroup.Prepend>
                                                                <InputGroup.Text>$</InputGroup.Text>
                                                            </InputGroup.Prepend>
                                                            <Form.Control type="number" placeholder="0.00"
                                                                          onChange={(e: any) => this.handleChangeInput(inputKey, e, "current")}
                                                            />
                                                        </InputGroup>
                                                        <InputGroup className={"col"}>
                                                            <InputGroup.Prepend>
                                                                <InputGroup.Text>$</InputGroup.Text>
                                                            </InputGroup.Prepend>
                                                            <Form.Control type="number" placeholder="0.00"
                                                                          onChange={(e: any) => this.handleChangeInput(inputKey, e, "goal")}
                                                            />
                                                        </InputGroup>
                                                    </Row>
                                                </Col>
                                            </Form.Group>)
                                        ) : ""}
                                        <Row>
                                            <Col>
                                                <Button variant="primary" className={"mb-4"} type="button"
                                                        onClick={() => this.handleAddRow()}><i
                                                    className={"la la-plus"}/> Agregar</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <Form.Group as={Row}>
                                        <Form.Label column md={5} sm={12} xs={12} className={"font-weight-bold"}>
                                            {total.title}
                                        </Form.Label>
                                        <Col md={7} sm={12} xs={12}>
                                            <Row>
                                                <InputGroup as={Col}>
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text
                                                            className={"font-weight-bold"}>$</InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <Form.Control className={"font-weight-bold"} type="text"
                                                                  placeholder="0.00"
                                                                  readOnly
                                                                  value={total.current.toFixed(2)}/>
                                                </InputGroup>
                                                <InputGroup as={Col}>
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text
                                                            className={"font-weight-bold"}>$</InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <Form.Control className={"font-weight-bold"} type="text"
                                                                  placeholder="0.00"
                                                                  readOnly
                                                                  value={total.goal.toFixed(2)}/>
                                                </InputGroup>
                                            </Row>
                                        </Col>
                                    </Form.Group>
                                    <Row>

                                    </Row>
                                </Col>
                                <Col lg={5} md={12} sm={12}>
                                    <Row className="m-1">
                                        <Alert variant={"success"} className="col-12 sb-tool-oe-result">
                                            <div className="d-flex">
                                                <section className="d-flex flex-column justify-content-center">
                                                    <img className="sb-tool-oe-result-icon" alt=""/>
                                                </section>
                                                <p className="m-0">{this._renderResult()}</p>
                                            </div>
                                        </Alert>
                                        <Alert variant={"primary"} className="hs-100 col-12 sb-tool-oe-result">
                                            <div className="d-flex">
                                                <section className="d-flex flex-column justify-content-center">
                                                    <img src="" alt="" className="sb-tool-oe-ds-icon"/>
                                                </section>
                                                {questions ? questions.map((row: any, key: number) => (
                                                    <p key={key} dangerouslySetInnerHTML={{__html: row}}/>
                                                )) : ""}
                                            </div>
                                        </Alert>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className={"mt-4"}>
                                <Col>
                                    <div className={"text-center"}>
                                        <ExportButton onClick={() => {
                                            if (isFinite(total.current) && isFinite(total.goal) && (total.current - total.goal) !== 0)
                                                this.setState({condEmailModal: true})
                                            else
                                                this.setState({
                                                    condAlertModal: true,
                                                    txtAlertModal: "Ingrese su información para realizar la exportación."
                                                })
                                        }}/>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>) : <div/>}
            <DescriptionModal
                show={this.state.condModal}
                onHide={() => {
                    this.setState({condModal: !this.state.condModal});
                }}
                txtdescription={this.state.description}
            />
            <LoadingModal
                show={this.state.loading}
                onHide={() => {
                    this.setState({loading: !this.state.loading});
                }}
            />
            <ErrorModal
                show={this.state.condError}
                onHide={() => {
                    this.setState({
                        condError: !this.state.condError,
                        redirect: this.state.condRedirect ? "/" : null,
                    })
                }}
                txtdescription={this.state.textError}
            />
            <EmailModal
                show={this.state.condEmailModal}
                value={this.state.emailAddress}
                onHide={() => {
                    this.setState({condEmailModal: false})
                }}
                onChange={(e: any) => {
                    this.setState({emailAddress: e.target.value})
                }}
                handleSubmit={(event: any) => {
                    event.preventDefault();
                    this._sendMail()
                }}
                txtdescription={this.state.description}
            />
            <SuccessModal
                show={this.state.condModalEmailSuccess}
                onHide={() => {
                    this.setState({condModalEmailSuccess: !this.state.condModalEmailSuccess})
                }}
                txtdescription={"¡Información enviada correctamente!"}
            />
            <AlertModal
                show={this.state.condAlertModal}
                onHide={() => {
                    this.setState({condAlertModal: !this.state.condAlertModal})
                }}
                txtdescription={this.state.txtAlertModal}
                txttitle={"Información"}
            />
        </Row>;
    }

    handleChangeInput(id: any, event: any, type: string) {
        let {inputs, total} = this.state;
        inputs[id][type] = isFinite(event.target.value) ? parseFloat(event.target.value) : 0;
        total.current = 0;
        total.goal = 0;
        inputs.forEach((row: any) => {
            total.current += isFinite(row.current) ? parseFloat(row.current) : 0;
            total.goal += isFinite(row.goal) ? parseFloat(row.goal) : 0;
        });
        const percent = ((total.goal / total.current) * 100).toFixed(2);
        this.setState({inputs, total, percent});

    }

    handleAddRow(): void {
        let {inputs, countNewRow} = this.state;
        countNewRow++;
        inputs.push({id: `other${countNewRow}`, name: "", current: 0, goal: 0, type: 1});
        this.setState({inputs, countNewRow});
    };
}

export default OptimizeExpensesTool;
