import React, {Component} from "react";
import Card from "react-bootstrap/cjs/Card";
import Row from "react-bootstrap/cjs/Row";
import Col from "react-bootstrap/cjs/Col";
import DescriptionModal from "../components/DescriptionModal";
import Alert from "react-bootstrap/cjs/Alert";
import Form from "react-bootstrap/cjs/Form";
import {DEBT_LEVEL_RESULTS} from "../constants/constants";
import Chart from "../components/Chart";
import {getCatalogDescendants} from "../actions/BudgetTool";
import {Redirect} from "react-router-dom";
import LoadingModal from "../components/LoadingModal";
import ErrorModal from "../components/ErrorModal";
import Title from "../components/Title";
import InputGroup from "react-bootstrap/InputGroup";
import Image from "react-bootstrap/Image";
import EmailModal from "../components/EmailModal";
import SuccessModal from "../components/SuccessModal";
import AlertModal from "../components/AlertModal";
import {sendMail} from "../actions/Global";
import ExportButton from "../components/ExportButton";

const fullSquirrel = require("../assets/images/design/full-squirrel.png");

class DebtLevel extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            toolTitle: "",
            description: "",
            inputs: [],
            income: {title: "Ingresos mensuales", amount: 0},
            total: {title: "Obligaciones financieras", expenses: 0, debtLevel: 0},
            resultDetail: {
                text: "",
                variant: "primary"
            },
            charts: {
                debtLevelPie: {
                    type: "pie",
                    options: {
                        title: {
                            text: "Porcentaje de deudas"
                        },
                        legend: {
                            layout: "vertical",
                            align: "right",
                            verticalAlign: "middle",
                            itemMarginTop: 10,
                            itemMarginBottom: 10
                        },
                        series: []
                    }
                },
                debtLevelColumn: {
                    type: "column",
                    options: {
                        title: {
                            text: ""
                        },
                        yAxis: {
                            labels: {
                                enabled: false
                            },
                            title: {
                                text: ""
                            }
                        },
                        series: []
                    }
                }
            },
            condModal: false,
            loading: true,
            condError: false,
            redirect: null,
            emailAddress: "",
            condEmailModal: false,
            attachmentId: null,
            textError: "Servicio no disponible por el momento, intente más tarde...",
            condModalEmailSuccess: false,
            txtAlertModal: "",
            condRedirect: false,
            condAlertModal: false
        };
    }

    chatsIng: object[] = [];

    async componentDidMount() {
        let condError = false;
        try {
            let budgetData: any = await getCatalogDescendants(25);
            if (!budgetData.message) {
                this.setState({
                    description: budgetData.jsonData.description,
                    toolTitle: budgetData.name,
                    condModal: true,
                    inputs: budgetData.children,
                    attachmentId: budgetData.jsonData.attachment
                });

            } else
                condError = true;
        } catch (error) {
            condError = true;
        }
        this.setState({condError, loading: false, condRedirect: condError});
    }

    async _sendMail() {
        let tables: any = [];
        const {income, resultDetail, inputs, total} = this.state;

        tables.push({
            perc: "%",
            label: "Detalle de obligaciones financieras",
            mount: "Monto ($)"
        });
        inputs.forEach((input: any) => {
            tables.push({
                perc: isFinite(input.percent) ? parseFloat(input.percent).toFixed(2) : "0.00",
                label: input.name,
                mount: isFinite(input.amount) ? parseFloat(input.amount).toFixed(2) : "0.00"
            });
        });
        let dataForm = {
            "title": this.state.toolTitle,
            "info": [
                {
                    "label": income.title,
                    "value": income.amount
                },
                {
                    "label": "NIVEL DE ENDEUDAMIENTO",
                    "value": total.debtLevel
                }
            ],
            "messages": [
                {
                    "text": resultDetail.text,
                    "type": resultDetail.variant
                }
            ],
            "table": tables,
            charts: this.chatsIng
        };
        let body = {
            "to": this.state.emailAddress,
            "params": {
                "name": this.state.toolTitle,
                "description": this.state.description
            },
            "attachmentsParams": [
                {
                    "id": this.state.attachmentId,
                    "params": dataForm
                }
            ]
        };
        try {
            await this.setState({condEmailModal: false, loading: true});
            await sendMail(body);
            this.setState({loading: false, condModalEmailSuccess: true});
        } catch (e) {
            this.setState({
                textError: "Ocurrió un error al enviar la información, intente nuevamente.",
                condError: true,
                loading: false,
                condRedirect: false
            });
        }
    }

    render() {
        const {inputs, income, total, resultDetail, charts} = this.state;
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>;
        }
        return (
            <Row>
                {this.state.toolTitle !== "" ? (
                    <Col>
                        <Title txtTitle={this.state.toolTitle} handleClick={() => {
                            this.setState({redirect: "/"});
                        }}/>
                        <Card className="mt-3 sb-card-content">
                            <Card.Body>
                                <Row>
                                    <Col xl={7} lg={12} md={12} sm={12}>
                                        <Row>
                                            <label
                                                className="col-md-6 col-sm-12 col-xs-12">
                                                <strong>{income.title}</strong>
                                            </label>
                                            <div className="col-md-6 col-sm-12 col-xs-12">
                                                <InputGroup>
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text>$</InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <Form.Control type="number" placeholder="0.00"
                                                                  onChange={(value: any) => this.handleSetIncome(value)}
                                                    />
                                                </InputGroup>
                                            </div>
                                        </Row>
                                        <Row className="mt-1">
                                            <label className="col-md-6 col-sm-12 col-xs-12"><strong>NIVEL DE
                                                ENDEUDAMIENTO</strong></label>
                                            <label
                                                className="col-md-6 col-sm-12 col-xs-12"><strong>{total.debtLevel}%</strong></label>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={6} sm={12} xs={12}>
                                                <label><strong>Detalle de obligaciones financieras</strong></label>
                                            </Col>
                                            <Col md={6} sm={12} xs={12}>
                                                <Row>
                                                    <p className="col text-overflow">Monto
                                                        ($)</p>
                                                    <p className="col text-overflow">%</p>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Form>
                                            {inputs ? inputs.map((input: any, inputKey: number) =>
                                                (<Form.Group key={input.id} as={Row}>
                                                    <Form.Label column md={6} sm={12} xs={12}>{input.name}</Form.Label>
                                                    <Col md={6} sm={12} xs={12}>
                                                        <Row>
                                                            <InputGroup className={"col"}>
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text>$</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <Form.Control type="number" placeholder="0.00"
                                                                              onChange={(value: any) => this.handleChangeInput(inputKey, value)}
                                                                />
                                                            </InputGroup>
                                                            <InputGroup className={"col"}>
                                                                <Form.Control type="number" placeholder="0.00"
                                                                              value={input.percent}
                                                                              readOnly
                                                                />
                                                                <InputGroup.Append>
                                                                    <InputGroup.Text>%</InputGroup.Text>
                                                                </InputGroup.Append>
                                                            </InputGroup>
                                                        </Row>
                                                    </Col>
                                                </Form.Group>)) : ""}
                                        </Form>
                                        <Row>
                                            <label
                                                className="col-md-6 col-sm-12 col-xs-12"><strong>{total.title}</strong></label>
                                            <Col md={6} sm={12} xs={12}>
                                                <Row>
                                                    <label
                                                        className="col text-overflow"><strong>$ {total.expenses}</strong></label>
                                                    <label className="col text-overflow"><strong>100%</strong></label>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xl={5} lg={12} md={12} sm={12}>
                                        {resultDetail.text && total.expenses > 0 && income.amount > 0 ?
                                            <div>
                                                <Row className="p-1">
                                                    <Alert variant={resultDetail.variant} className="col-12">
                                                        <p>{resultDetail.text}</p>
                                                    </Alert>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        {total.expenses > 0 ? (<Chart type={charts.debtLevelPie.type}
                                                                                      callback={(img: any) => this.getReports(img, "debtLevelPie")}
                                                                                      options={charts.debtLevelPie.options}
                                                            />
                                                        ) : <Row/>}

                                                    </Col>
                                                </Row>
                                            </div>
                                            :
                                            <div className={"container-squirrel-explain"}>
                                                <div className={"container-speech"}>
                                                    <h3>Tus resultados se mostrarán aquí</h3>
                                                </div>
                                                <Image src={fullSquirrel} fluid={true}/>
                                            </div>
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {total.expenses > 0 ? (<Chart type={charts.debtLevelColumn.type}
                                                                      callback={(img: any) => this.getReports(img, "debtLevelColumn")}
                                                                      options={charts.debtLevelColumn.options}/>
                                        ) : <Row/>}
                                    </Col>
                                </Row>
                                <Row className={"mt-4"}>
                                    <Col>
                                        <div className={"text-center"}>
                                            <ExportButton onClick={() => {
                                                if (resultDetail.text && total.expenses > 0 && income.amount > 0)
                                                    this.setState({condEmailModal: true});
                                                else
                                                    this.setState({
                                                        condAlertModal: true,
                                                        txtAlertModal: "Ingrese su información para realizar la exportación."
                                                    });
                                            }}/>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>) : <div/>}
                <DescriptionModal
                    show={this.state.condModal}
                    onHide={() => {
                        this.setState({condModal: !this.state.condModal});
                    }}
                    txtdescription={this.state.description}
                />
                <LoadingModal
                    show={this.state.loading}
                    onHide={() => {
                        this.setState({loading: !this.state.loading});
                    }}
                />
                <ErrorModal
                    show={this.state.condError}
                    onHide={() => {
                        this.setState({
                            condError: !this.state.condError,
                            redirect: this.state.condRedirect ? "/" : null,
                        });
                    }}
                    txtdescription={this.state.textError}
                />
                <EmailModal
                    show={this.state.condEmailModal}
                    value={this.state.emailAddress}
                    onHide={() => {
                        this.setState({condEmailModal: false});
                    }}
                    onChange={(e: any) => {
                        this.setState({emailAddress: e.target.value});
                    }}
                    handleSubmit={(event: any) => {
                        event.preventDefault();
                        this._sendMail();
                    }}
                    txtdescription={this.state.description}
                />
                <SuccessModal
                    show={this.state.condModalEmailSuccess}
                    onHide={() => {
                        this.setState({condModalEmailSuccess: !this.state.condModalEmailSuccess});
                    }}
                    txtdescription={"¡Información enviada correctamente!"}
                />
                <AlertModal
                    show={this.state.condAlertModal}
                    onHide={() => {
                        this.setState({condAlertModal: !this.state.condAlertModal});
                    }}
                    txtdescription={this.state.txtAlertModal}
                    txttitle={"Información"}
                />
            </Row>
        );
    }

    private handleChangeInput(id: number, event: any) {
        let {inputs} = this.state;
        inputs[id].amount = isFinite(event.target.value) ? parseFloat(event.target.value) : 0;
        this.processData(inputs);
    }

    private handleSetIncome(event: any) {
        let {inputs, income} = this.state;
        income.amount = isFinite(event.target.value) ? parseFloat(event.target.value) : 0;
        this.processData(inputs, income);
    }

    processData(inputs: any, income = this.state.income) {
        let {total, resultDetail, charts} = this.state;
        total.expenses = 0;
        inputs.forEach((input: any) => {
            total.expenses += isFinite(input.amount) ? parseFloat(input.amount) : 0;
        });
        total.debtLevel = ((total.expenses / income.amount) * 100 || 0).toFixed(2);
        inputs.forEach((input: any, key: number) => {
            inputs[key].percent = ((input.amount / total.expenses) * 100 || 0).toFixed(2);
        });
        total.debtLevel = isFinite(total.debtLevel) ? parseFloat(total.debtLevel) : 0;
        if (total.expenses > 0) {
            DEBT_LEVEL_RESULTS.find((result) => {
                if (total.debtLevel >= result.min && total.debtLevel <= result.max) {
                    resultDetail.text = result.result.replace("{debtLevel}", total.debtLevel);
                    resultDetail.variant = result.variant;
                    return true;
                }
                return false;
            });
            const {debtLevelPieSeries, debtLevelColumnSeries} = this.processChats(inputs, income, total);
            charts.debtLevelPie.options.series = [debtLevelPieSeries];
            charts.debtLevelColumn.options.series = debtLevelColumnSeries;
        }
        this.setState({inputs, total, income, resultDetail, charts});
    }

    processChats(inputs: any, income: any, total: any) {
        const debtLevelPieSeries: any = {
            name: "Deuda",
            colorByPoint: true,
            data: []
        };
        const debtLevelColumnSeries: any = {
            name: "Monto",
            colorByPoint: true,
            data: [
                {
                    name: income.title,
                    y: parseFloat(income.amount) || 0,
                },
                {
                    name: total.title,
                    y: parseFloat(total.expenses) || 0,
                    color: "red"
                }
            ]
        };
        inputs.map((row: any) => {
            debtLevelPieSeries.data.push({
                name: row.name,
                y: parseFloat(row.percent)
            });
            return true;
        });
        return {debtLevelPieSeries, debtLevelColumnSeries};
    }

    getReports = (chart: any, type: string): void => {
        if (type === "debtLevelColumn")
            this.chatsIng[1] = {graph: chart};
        else
            this.chatsIng[0] = {graph: chart};
    };
}

export default DebtLevel;
