import React, {Component} from "react";
import {render} from "react-dom";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";

exporting(Highcharts);

const pieDefaultOptions = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie"
    },
    responsive: {
        rules: [{
            condition: {
                maxHeight: "100px"
            },
            chartOptions: {
                legend: {
                    enabled: false
                }
            }
        }],
    },
    title: {
        text: ""
    },
    tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>"
    },
    accessibility: {
        point: {
            valueSuffix: "%"
        }
    },
    plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
                enabled: true,
                distance: -40,
                formatter: function (): any {
                    // @ts-ignore
                    return this.y + "%";
                }
            },
            showInLegend: true
        }
    },
    series: []
};
const columnDefaultOptions = {
    chart: {
        type: "column"
    },
    title: {
        text: "Browser market shares. January, 2018"
    },
    accessibility: {
        announceNewData: {
            enabled: true
        }
    },
    legend: {
        enabled: false
    },
    xAxis: {
        type: "category"
    },
    plotOptions: {
        series: {
            borderWidth: 0,
            dataLabels: {
                enabled: true,
                format: "$ {point.y:.1f}"
            }
        }
    },
    series: []
};

class Chart extends Component<any, any> {
    private chartRef: any;

    componentDidMount() {
        this.chartRef = React.createRef();
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        const {callback} = this.props;
        const svg = this.chartRef.current.chart.getSVG();
        if (callback) {
            callback("data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(svg))));
        }
    }

    render() {
        const {type, options} = this.props;
        return (<HighchartsReact
            highcharts={Highcharts}
            options={setOptions(type, options)}
            ref={this.chartRef}
        />);
    }
}

const setOptions = (type: string, options: object) => {
    if (type === "pie") {
        return {...pieDefaultOptions, ...options};
    } else if (type === "column") {
        return {...columnDefaultOptions, ...options};
    }
};
render(<Chart/>, document.getElementById("root"));
export default Chart;
