import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import {routes} from "./constants/routes";
import Container from "react-bootstrap/Container";
import Header from "./components/Header";
import Footer from "./components/Footer";

function App() {
    return (
        <Router>
            <Header/>
            <Container className={"sb-container"}>
                <Switch>
                    {routes.map((route) => (
                        <Route
                            key={route.name}
                            path={route.path}
                            render={props => (
                                <route.component {...props}/>
                            )}
                        />
                    ))}
                </Switch>
            </Container>
            <Footer/>
        </Router>
    );
}

export default App;
