import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form"
import Alert from "react-bootstrap/Alert"

const EmailModal = (props: any) => {
    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            onHide={props.onHide}
        >
            <Form onSubmit={props.handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Ingresa tu dirección de correo electrónico...</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Alert variant="success">
                        La información ingresada en la herramienta financiera será enviada a la dirección de correo
                        electrónico que indiques a continuación:
                    </Alert>

                    <Form.Group controlId="sb-email">
                        <Form.Control required type="email" placeholder="Ej. ejemplo@email.com" value={props.value}
                                      onChange={props.onChange}/>
                    </Form.Group>
                </Modal.Body>

                <Modal.Footer>
                    <Button type={"submit"} className={"text-center"} size={"lg"} variant="primary">ENVIAR</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default EmailModal;
