import React, {Component} from "react";
import {Redirect} from "react-router-dom";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Title from "../components/Title";
import InputGroup from "react-bootstrap/InputGroup";
import {getCatalogDescendants} from "../actions/BudgetTool";
import DescriptionModal from "../components/DescriptionModal";
import LoadingModal from "../components/LoadingModal";
import ErrorModal from "../components/ErrorModal";
import EmailModal from "../components/EmailModal";
import {sendMail} from "../actions/Global";
import SuccessModal from "../components/SuccessModal";
import AlertModal from "../components/AlertModal";
import ExportButton from "../components/ExportButton";
import Card from "react-bootstrap/cjs/Card";


class BudgetTool extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            toolTitle: "",
            description: "",
            incomes: {name: "", children: []},
            totalIncomes: 0,
            expenses: {name: "", children: []},
            totalExpenses: 0,
            additionalExpenses: [],
            totalSaving: 0,
            condModal: false,
            loading: true,
            condError: false,
            redirect: null,
            emailAddress: "",
            condEmailModal: false,
            attachmentId: null,
            textError: "Servicio no disponible por el momento, intente más tarde...",
            condModalEmailSuccess: false,
            txtAlertModal: "",
            condRedirect: false,
            condAlertModal: false
        };
    }

    async componentDidMount() {
        let condError = false;
        try {
            let budgetData: any = await getCatalogDescendants(1);
            if (!budgetData.message)
                this.setState({
                    description: budgetData.jsonData.description,
                    toolTitle: budgetData.name,
                    condModal: true,
                    attachmentId: budgetData.jsonData.attachment
                });
            else
                condError = true;
            let incomes: any = await getCatalogDescendants(2);
            let expenses: any = await getCatalogDescendants(3);
            if (!incomes.message)
                this.setState({incomes});
            else
                condError = true;
            if (!expenses.message)
                this.setState({expenses});
            else
                condError = true;
        } catch (error) {
            condError = true;
        }
        this.setState({condError, loading: false, condRedirect: condError});
    }

    _addRow = (indexExpense: number) => {
        let expenses = this.state.expenses;
        let expensesChildren = expenses.children[indexExpense].children;
        expensesChildren.push({id: "e_" + (expensesChildren.length), name: "", value: "", children: [], type: 1});
        this.setState({expenses: expenses});
    };

    _handleIncomesChange = (id: number, e: any) => {
        let totalIncomesAux = 0;
        let incomesAux = this.state.incomes;
        incomesAux.children.forEach((income: any, index: any) => {
            if (income.children.length > 0) {
                income.children.forEach((incomeChild: any, indexChild: any) => {
                    if (id === incomeChild.id)
                        incomesAux.children[index].children[indexChild].value = isFinite(e.target.value) ? e.target.value : 0;
                    totalIncomesAux += incomeChild.value ? parseFloat(incomeChild.value) : 0;
                });
            } else {
                if (id === income.id)
                    incomesAux.children[index].value = isFinite(e.target.value) ? e.target.value : 0;
                totalIncomesAux += income.value ? parseFloat(income.value) : 0;
            }
        });
        this.setState({incomes: incomesAux, totalIncomes: totalIncomesAux, totalSaving: totalIncomesAux * .1});
    };

    _handleExpensesChange = (id: number, e: any) => {
        let totalExpensesAux = 0;
        let expensesAux = this.state.expenses;
        expensesAux.children.forEach((expense: any, index: any) => {
            if (expense.children.length > 0) {
                expense.children.forEach((expenseChild: any, indexChild: any) => {
                    if (id === expenseChild.id)
                        expensesAux.children[index].children[indexChild].value = isFinite(e.target.value) ? e.target.value : 0;
                    totalExpensesAux += expenseChild.value ? parseFloat(expenseChild.value) : 0;
                });
            } else {
                if (id === expense.id)
                    expensesAux.children[index].value = isFinite(e.target.value) ? e.target.value : 0;
                totalExpensesAux += expense.value ? parseFloat(expense.value) : 0;
            }
        });
        //get additional expenses
        this.state.additionalExpenses.forEach((expense: any) => {
            totalExpensesAux += parseFloat(expense.value);
        });
        this.setState({expenses: expensesAux, totalExpenses: totalExpensesAux});
    };

    _handleExpensesChangeName = (indexExpense: number, indexChild: number, e: any) => {
        let expensesAux = this.state.expenses;
        if (expensesAux.children[indexExpense].children.length > 0)
            expensesAux.children[indexExpense].children[indexChild].name = e.target.value;
        else
            expensesAux.children[indexExpense].name = e.target.value;
        this.setState({expenses: expensesAux});
    };

    async _sendMail() {
        let tables: any = [];
        //incomes
        tables.push([{type: "h3", value: this.state.incomes.name}]);
        this.state.incomes.children.forEach((income: any) => {
            if (income.children.length > 0) {
                tables[tables.length - 1].push({type: "h4", value: income.name});
                income.children.forEach((incomeChild: any) => {
                    tables[tables.length - 1].push({
                        type: "field",
                        label: incomeChild.name,
                        value: isFinite(incomeChild.value) ? parseFloat(incomeChild.value).toFixed(2) : "0.00"
                    });
                });
            } else {
                tables[tables.length - 1].push({
                    type: "field",
                    label: income.name,
                    value: isFinite(income.value) ? parseFloat(income.value).toFixed(2) : "0.00"
                });
            }
        });
        tables[tables.length - 1].push({
            type: "total",
            label: "TOTAL INGRESOS",
            value: isFinite(this.state.totalIncomes) ? parseFloat(this.state.totalIncomes).toFixed(2) : "0.00"
        });
        //expenses
        tables.push([{type: "h3", value: this.state.expenses.name}]);
        this.state.expenses.children.forEach((expense: any) => {
            if (expense.children.length > 0) {
                tables[tables.length - 1].push({type: "h4", value: expense.name});
                expense.children.forEach((expenseChild: any) => {
                    tables[tables.length - 1].push({
                        type: "field",
                        label: expenseChild.name,
                        value: isFinite(expenseChild.value) ? parseFloat(expenseChild.value).toFixed(2) : "0.00"
                    });
                });
            } else {
                tables[tables.length - 1].push({
                    type: "field",
                    label: expense.name,
                    value: isFinite(expense.value) ? parseFloat(expense.value).toFixed(2) : "0.00"
                });
            }
        });
        tables[tables.length - 1].push({
            type: "total",
            label: "TOTAL GASTOS",
            value: isFinite(this.state.totalExpenses) ? parseFloat(this.state.totalExpenses).toFixed(2) : "0.00"
        });
        //saving
        tables.push([{type: "h3", value: "AHORRO"}]);
        tables[tables.length - 1].push({
            type: "field",
            label: "Ahorro actual",
            value: isFinite(this.state.totalSaving) ? parseFloat(this.state.totalSaving).toFixed(2) : "0.00"
        });
        tables[tables.length - 1].push({
            type: "text",
            label: "",
            value: `El valor sugerido a considerar para tu ahorro es del 10% de tus ingresos.`
        });
        //balance
        tables.push([{type: "h3", value: "BALANCE FINAL"}]);
        tables[tables.length - 1].push({
            type: "field",
            label: "TOTAL INGRESOS",
            value: parseFloat(this.state.totalIncomes).toFixed(2)
        });
        tables[tables.length - 1].push({
            type: "field",
            label: "TOTAL GASTOS",
            value: parseFloat(this.state.totalExpenses).toFixed(2)
        });
        tables[tables.length - 1].push({
            type: "field",
            label: "TOTAL AHORROS",
            value: parseFloat(this.state.totalSaving).toFixed(2)
        });
        tables[tables.length - 1].push({
            type: "total",
            label: "BALANCE GENERAL",
            value: (parseFloat(this.state.totalIncomes) - parseFloat(this.state.totalExpenses) - parseFloat(this.state.totalSaving)).toFixed(2)
        });

        let dataForm = {
            "title": this.state.toolTitle,
            "tables": tables
        };
        let body = {
            "to": this.state.emailAddress,
            "params": {
                "name": this.state.toolTitle,
                "description": this.state.description
            },
            "attachmentsParams": [
                {
                    "id": this.state.attachmentId,
                    "params": dataForm
                }
            ]
        };
        try {
            await this.setState({condEmailModal: false, loading: true});
            await sendMail(body);
            this.setState({loading: false, condModalEmailSuccess: true});
        } catch (e) {
            this.setState({
                textError: "Ocurrió un error al enviar la información, intente nuevamente.",
                condError: true,
                loading: false,
                condRedirect: false
            });
        }
    }

    _deleteRow = (indexExpense: number, indexRow: number) => {
        let expenses = this.state.expenses;
        expenses.children[indexExpense].children.splice(indexRow, 1);
        this.setState({expenses});
    };

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>;
        }
        return (
            <div className={"row"}>
                {this.state.toolTitle !== "" ? (
                    <div className={"col"}>
                        <Title txtTitle={this.state.toolTitle} handleClick={() => {
                            this.setState({redirect: "/"});
                        }}/>
                        <Card className={"mt-3 sb-card-content"}>
                            <Card.Body>
                                <div className={"row"}>
                                    <Col>
                                        <Form>
                                            <h5 className={"text-center mb-3"}>{this.state.incomes.name}</h5>
                                            <Row>
                                                {this.state.incomes.children.map((income: any, index: number) => {
                                                    if (income.children.length > 0) {
                                                        return (
                                                            <Col xs={12} md={6} key={index}>
                                                                <h6 className={"font-weight-bold"}>{income.name}</h6>
                                                                {income.children.map((incomeChild: any, indexChild: number) => (
                                                                        <Form.Group key={indexChild} as={Row}
                                                                                    controlId={"income" + indexChild}>
                                                                            <Form.Label column sm={6} md={5} lg={6}>
                                                                                {incomeChild.name}
                                                                            </Form.Label>
                                                                            <Col sm={6} md={7} lg={6}>
                                                                                <InputGroup>
                                                                                    <InputGroup.Prepend>
                                                                                        <InputGroup.Text>$</InputGroup.Text>
                                                                                    </InputGroup.Prepend>
                                                                                    <Form.Control type="number"
                                                                                                  placeholder="0.00"
                                                                                                  onChange={(e) => {
                                                                                                      this._handleIncomesChange(incomeChild.id, e);
                                                                                                  }}/>
                                                                                </InputGroup>
                                                                            </Col>
                                                                        </Form.Group>
                                                                    )
                                                                )}
                                                            </Col>
                                                        );
                                                    } else {
                                                        return (
                                                            <Form.Group key={index} as={Row}
                                                                        controlId={"income" + index}>
                                                                <Form.Label column sm={6} md={5} lg={6}>
                                                                    {income.name}
                                                                </Form.Label>
                                                                <Col sm={6} md={7} lg={6}>
                                                                    <InputGroup>
                                                                        <InputGroup.Prepend>
                                                                            <InputGroup.Text>$</InputGroup.Text>
                                                                        </InputGroup.Prepend>
                                                                        <Form.Control type="number" placeholder="0.00"
                                                                                      onChange={(e) => {
                                                                                          this._handleIncomesChange(income.id, e);
                                                                                      }}/>
                                                                    </InputGroup>
                                                                </Col>
                                                            </Form.Group>
                                                        );
                                                    }
                                                })}
                                            </Row>
                                            <Form.Group as={Row} controlId={"incomeTotal"}>
                                                <Form.Label column sm={6} md={4} lg={3}
                                                            className={"font-weight-bold font-larger"}>
                                                    TOTAL INGRESOS
                                                </Form.Label>
                                                <Col sm={6} md={8} lg={3}>
                                                    <InputGroup>
                                                        <InputGroup.Prepend>
                                                            <InputGroup.Text
                                                                className={"font-weight-bold"}>$</InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <Form.Control className={"font-weight-bold"} type="text"
                                                                      placeholder="0.00"
                                                                      readOnly
                                                                      value={this.state.totalIncomes.toFixed(2)}/>
                                                    </InputGroup>
                                                </Col>
                                            </Form.Group>
                                            <hr/>
                                            <h5 className={"text-center mb-3"}>{this.state.expenses.name}</h5>
                                            <Row>
                                                {this.state.expenses.children.length > 0 ?
                                                    <Col xs={12} md={6}>
                                                        <h6 className={"font-weight-bold"}>{this.state.expenses.children[0].name}</h6>
                                                        {this.state.expenses.children[0].children.map((expenseChild: any, indexChild: number) => (
                                                                <Form.Group key={indexChild} as={Row}
                                                                            controlId={"expense" + indexChild}>
                                                                    <Col sm={6} md={5} lg={6}>
                                                                        {expenseChild.type === 1 ?
                                                                            <InputGroup>
                                                                                <Form.Control type="text"
                                                                                              placeholder="Gasto"
                                                                                              onChange={(e) => {
                                                                                                  this._handleExpensesChangeName(0, indexChild, e);
                                                                                              }} value={expenseChild.name}/>
                                                                                <InputGroup.Append>
                                                                                    <Button variant="danger"
                                                                                            type="button"
                                                                                            onClick={() => {
                                                                                                this._deleteRow(0, indexChild);
                                                                                            }}><i
                                                                                        className={"fa fa-minus"}/></Button>
                                                                                </InputGroup.Append>
                                                                            </InputGroup>
                                                                            : expenseChild.name}
                                                                    </Col>
                                                                    <Col sm={6} md={7} lg={6}>
                                                                        <InputGroup>
                                                                            <InputGroup.Prepend>
                                                                                <InputGroup.Text>$</InputGroup.Text>
                                                                            </InputGroup.Prepend>
                                                                            <Form.Control type="number" placeholder="0.00"
                                                                                          onChange={(e) => {
                                                                                              this._handleExpensesChange(expenseChild.id, e);
                                                                                          }} value={expenseChild.value}/>
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group>
                                                            )
                                                        )}
                                                        <Button variant="primary" className={"mb-2"} type="button"
                                                                onClick={() => {
                                                                    this._addRow(0);
                                                                }}><i
                                                            className={"la la-plus"}/> Agregar</Button>
                                                    </Col>
                                                    : ""}
                                                {this.state.expenses.children.length > 0 ?
                                                    <Col xs={12} md={6}>
                                                        {this.state.expenses.children.map((expense: any, index: number) => {
                                                            if (expense.children.length > 0 && index > 0) {
                                                                return (
                                                                    <div key={index}>
                                                                        <h6 className={"font-weight-bold"}>{expense.name}</h6>
                                                                        {expense.children.map((expenseChild: any, indexChild: number) => (
                                                                                <Form.Group key={indexChild} as={Row}
                                                                                            controlId={"expense" + indexChild}>
                                                                                    <Col sm={6} md={5} lg={6}>
                                                                                        {expenseChild.type === 1 ?
                                                                                            <InputGroup>
                                                                                                <Form.Control type="text"
                                                                                                              placeholder="Gasto"
                                                                                                              onChange={(e) => {
                                                                                                                  this._handleExpensesChangeName(index, indexChild, e);
                                                                                                              }}
                                                                                                              value={expenseChild.name}/>
                                                                                                <InputGroup.Append>
                                                                                                    <Button variant="danger"
                                                                                                            type="button"
                                                                                                            onClick={() => {
                                                                                                                this._deleteRow(index, indexChild);
                                                                                                            }}><i
                                                                                                        className={"fa fa-minus"}/></Button>
                                                                                                </InputGroup.Append>
                                                                                            </InputGroup>
                                                                                            : expenseChild.name}
                                                                                    </Col>
                                                                                    <Col sm={6} md={7} lg={6}>
                                                                                        <InputGroup>
                                                                                            <InputGroup.Prepend>
                                                                                                <InputGroup.Text>$</InputGroup.Text>
                                                                                            </InputGroup.Prepend>
                                                                                            <Form.Control type="number"
                                                                                                          placeholder="0.00"
                                                                                                          onChange={(e) => {
                                                                                                              this._handleExpensesChange(expenseChild.id, e);
                                                                                                          }}
                                                                                                          value={expenseChild.value}/>
                                                                                        </InputGroup>
                                                                                    </Col>
                                                                                </Form.Group>
                                                                            )
                                                                        )}
                                                                        <Button variant="primary" className={"mb-3"}
                                                                                type="button"
                                                                                onClick={() => {
                                                                                    this._addRow(index);
                                                                                }}><i
                                                                            className={"la la-plus"}/> Agregar</Button>
                                                                    </div>
                                                                );
                                                            } else {
                                                                return "";
                                                            }
                                                        })}
                                                    </Col>
                                                    : ""}
                                            </Row>
                                            <Form.Group as={Row} controlId={"expensesTotal"}>
                                                <Form.Label column sm={6} md={4} lg={3}
                                                            className={"font-weight-bold font-larger"}>
                                                    TOTAL GASTOS
                                                </Form.Label>
                                                <Col sm={6} md={8} lg={3}>
                                                    <InputGroup>
                                                        <InputGroup.Prepend>
                                                            <InputGroup.Text
                                                                className={"font-weight-bold"}>$</InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <Form.Control className={"font-weight-bold"} type="text"
                                                                      placeholder="0.00"
                                                                      readOnly
                                                                      value={this.state.totalExpenses.toFixed(2)}/>
                                                    </InputGroup>
                                                </Col>
                                            </Form.Group>
                                            <hr/>
                                            <Row>
                                                <Col xs={12} md={6}>
                                                    <h5>AHORRO</h5>
                                                    <Form.Group as={Row}
                                                                controlId={"ahorro"}>
                                                        <Form.Label column sm={6} md={5} lg={6}>
                                                            Ahorro actual
                                                        </Form.Label>
                                                        <Col sm={6} md={7} lg={6}>
                                                            <InputGroup>
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text>$</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <Form.Control readOnly type="text" placeholder="0.00"
                                                                              value={this.state.totalSaving.toFixed(2)}/>
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>
                                                    <Row>
                                                        <Col sm={6} md={5} lg={6}/>
                                                        <Col sm={6} md={7} lg={6}>
                                                            <p className={"savingLabel"}>El valor sugerido a considerar
                                                                para tu
                                                                ahorro
                                                                es del 10% de tus ingresos.</p>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <h5>BALANCE FINAL</h5>
                                                    <Form.Group as={Row}
                                                                controlId={"totalIngresos"}>
                                                        <Form.Label column sm={6} md={5} lg={6}>
                                                            TOTAL INGRESOS
                                                        </Form.Label>
                                                        <Col sm={6} md={7} lg={6}>
                                                            <InputGroup>
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text>$</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <Form.Control readOnly type="text" placeholder="0.00"
                                                                              value={this.state.totalIncomes.toFixed(2)}/>
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row}
                                                                controlId={"totalGastos"}>
                                                        <Form.Label column sm={6} md={5} lg={6}>
                                                            TOTAL GASTOS
                                                        </Form.Label>
                                                        <Col sm={6} md={7} lg={6}>
                                                            <InputGroup>
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text>$</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <Form.Control readOnly type="text" placeholder="0.00"
                                                                              value={this.state.totalExpenses.toFixed(2)}/>
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row}
                                                                controlId={"totalGastos"}>
                                                        <Form.Label column sm={6} md={5} lg={6}>
                                                            TOTAL AHORROS
                                                        </Form.Label>
                                                        <Col sm={6} md={7} lg={6}>
                                                            <InputGroup>
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text>$</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <Form.Control readOnly type="text" placeholder="0.00"
                                                                              value={this.state.totalSaving.toFixed(2)}/>
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row}
                                                                controlId={"totalGastos"}>
                                                        <Form.Label column sm={6} md={5} lg={6}
                                                                    className={"font-weight-bold font-larger"}>
                                                            BALANCE GENERAL
                                                        </Form.Label>
                                                        <Col sm={6} md={7} lg={6}>
                                                            <InputGroup>
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text
                                                                        className={"font-weight-bold"}>$</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <Form.Control className={"font-weight-bold"} readOnly
                                                                              type="text"
                                                                              placeholder="0.00"
                                                                              value={(this.state.totalIncomes - this.state.totalExpenses - this.state.totalSaving).toFixed(2)}/>
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <hr/>
                                        </Form>
                                    </Col>
                                </div>
                                <Row className={"mt-4"}>
                                    <Col>
                                        <div className={"text-center"}>
                                            <ExportButton onClick={() => {
                                                if (isFinite(this.state.totalIncomes - this.state.totalExpenses - this.state.totalSaving) && (this.state.totalIncomes - this.state.totalExpenses - this.state.totalSaving) !== 0)
                                                    this.setState({condEmailModal: true});
                                                else
                                                    this.setState({
                                                        condAlertModal: true,
                                                        txtAlertModal: "Ingrese su información para realizar la exportación."
                                                    });
                                            }}/>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </div>
                ) : <div/>}
                <AlertModal
                    show={this.state.condAlertModal}
                    onHide={() => {
                        this.setState({condAlertModal: !this.state.condAlertModal});
                    }}
                    txtdescription={this.state.txtAlertModal}
                    txttitle={"Información"}
                />
                <DescriptionModal
                    show={this.state.condModal}
                    onHide={() => {
                        this.setState({condModal: !this.state.condModal});
                    }}
                    txtdescription={this.state.description}
                />
                <LoadingModal
                    show={this.state.loading}
                    onHide={() => {
                        this.setState({loading: !this.state.loading});
                    }}
                />
                <ErrorModal
                    show={this.state.condError}
                    onHide={() => {
                        this.setState({
                            condError: !this.state.condError,
                            redirect: this.state.condRedirect ? "/" : null,
                        });
                    }}
                    txtdescription={this.state.textError}
                />
                <EmailModal
                    show={this.state.condEmailModal}
                    value={this.state.emailAddress}
                    onHide={() => {
                        this.setState({condEmailModal: false});
                    }}
                    onChange={(e: any) => {
                        this.setState({emailAddress: e.target.value});
                    }}
                    handleSubmit={(event: any) => {
                        event.preventDefault();
                        this._sendMail();
                    }}
                    txtdescription={this.state.description}
                />
                <SuccessModal
                    show={this.state.condModalEmailSuccess}
                    onHide={() => {
                        this.setState({condModalEmailSuccess: !this.state.condModalEmailSuccess});
                    }}
                    txtdescription={"¡Información enviada correctamente!"}
                />
            </div>
        );
    }
}

export default BudgetTool;
